
import React, { useEffect, useState } from "react";
import {
    Button,
    Grid,
    Paper,
    Snackbar,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { httpclient } from "../../../utils/httpClient";
import featureServer from "../../../utils/featureServer";
import TableComponent from "../../Settings/TableComponent"



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
        margin: "0",
    },
}));

const columns = [
    { id: "sn", name: "SN" },
    { id: "fileName", name: "File" },
    { id: "importDate", name: "Import Date" },
    { id: "status", name: "Status" },
    { id: "progress", name: "Progress" },
]

const FileUploadStatus = () => {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        fileUploadStatus();
        const intervalId = setInterval(fileUploadStatus, 10 * 1000); // Fetch data every 60 seconds
        return () => clearInterval(intervalId);
    }, []);

    const fileUploadStatus = () => {
        //setLoading(true);
        httpclient
            .get(featureServer ? `https://ubatemp.retailcare.com.au/php/excel-files-status` : `https://uba.synccare.com.au/php/excel-files-status`)
            .then(({ data }) => {
                if (data.success) {
                    setLoading(false);
                    setFiles(data.data);
                }
            });
    };
    
    const handleReprocess = () => {
        console.log("Repreocesss")
    }


    // const handleDownload = () => {
    //     httpclient
    //       .get(featureServer ? `https://ubatemp.retailcare.com.au/php/download-stock-codes` : `https://uba.synccare.com.au/php/download-missing-stock-codes`, {
    //         responseType: 'blob',
    //       })
    //       .then(({ data }) => {
    //         if (data) {
    //           const url = window.URL.createObjectURL(new Blob([data]));
    //           const link = document.createElement('a');
    //           link.href = url;
    //           link.setAttribute('download', 'missing_stock_codes.csv');
    //           document.body.appendChild(link);
    //           link.click();
    //           document.body.removeChild(link);
    //         }
    //       })
    //       .catch((error) => {
    //         console.error("Error downloading file: ", error);
    //       });
    //   };

    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <h1>File Upload Status</h1>
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    {/* <Button color="primary" variant="contained" onClick={handleDownload}>
                        Download All
                    </Button> */}
                </Grid>
                {/* <Grid item md={6}>
                    <TableContainer component={Paper}>
                        <Table aria-label="missing stock codes table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: "bold" }}>SN</TableCell>
                                    <TableCell style={{ fontWeight: "bold" }}>Missing Stock Code</TableCell>
                                </TableRow>
                            </TableHead>
                            {!loading ? files.length > 0 ? files.map((code, index) => (
                                <TableBody>

                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{code}</TableCell>
                                    </TableRow>

                                </TableBody>
                            )) : <TableBody>No Missing Codes</TableBody> : <TableBody>Fetching Stock Codes...</TableBody>}
                        </Table>
                    </TableContainer>
                </Grid> */}
                <TableComponent
                    columns={columns}
                    rows={files}
                    loading={loading}
                    handleReprocess={handleReprocess}
                    uploadStatus={true}
                />
            </Grid>

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleCloseSnack}
            >
                <Alert
                    onClose={handleCloseSnack}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default FileUploadStatus;
