import {
  ArrowBack,
  Check,
  Clear,
  Close,
  CloudUpload,
  Done,
  DoneAll,
  Download,
  Edit,
  HighlightOff,
  HourglassBottom,
  HourglassEmptyOutlined,
  KeyboardArrowLeft,
  LockOpen,
  RestartAlt,
  Sync,
  SyncAlt,
  SyncLock,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  styled,
  useTheme,
  Snackbar,
  CircularProgress,
  Tooltip,
  TextField,
  Input,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTableView from "../../../../Components/BasicTableView";
import BasicTableView1 from "../../../../Components/BasicTableView1";
import { httpclient } from "../../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";
import StatusDialog from "../StatusDialog";
import AssignedDialog from "../AssignedDialog";
import EditDialog from "../EditDialog";
import CancelDialog from "../CancelDialog";
import CancelProductDialog from "../CancelProductDialog";
import BasicTable2 from "../../../../Components/BasicTable2";
import BasicTable3 from "../../../../Components/BasicTable3";
import ProcessDialog from "../ProcessDialog";
import Autocomplete from "react-google-autocomplete";
import LocationSearchInput from "../../../../Components/GoogleAddress";
import PreSellTableComponent from "./PreSellTableComponent/PreSellTableComponent";
import BasicTableViewNetsuite from "../../../../Components/BasicTableViewNetsuite";
import RefetchSoubaDialog from "../RefetchSoubaDialog";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const presellOrderContent = (
  <Tooltip title="Pre-Sales Order">
    <Button
      sx={{
        position: "absolute",
        top: "25%",
        right: "0",
        color: "white",
        background: "green",
        borderRadius: "20%",
        fontSize: "12px",
        minWidth: "10px",
        padding: "0px 5px 0px 5px",
        "&:hover": {
          color: "white",
          background: "green",
        },
      }}
    >
      PS
    </Button>
  </Tooltip>
);

const presellOrderContent1 = (
  <Tooltip title="Pre-Sales Receipted">
    <Button
      sx={{
        position: "absolute",
        top: "25%",
        right: "0",
        color: "white",
        background: "green",
        borderRadius: "20%",
        fontSize: "12px",
        minWidth: "10px",
        padding: "0px 5px 0px 5px",
        "&:hover": {
          color: "white",
          background: "green",
        },
      }}
    >
      PR
    </Button>
  </Tooltip>
);

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "210px",
  maxWidth: "320px",
  fontWeight: "600",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "15px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
  "& input": {
    padding: "10px",
    width: "150px",
  },
  "& .MuiSelect-select": {
    padding: "10px",
  },
  "& button": {
    padding: "11px !important",
    minWidth: "40px",
  },
}));

const PricePolicyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: "5px",
  "& h5": {
    margin: "5px",
  },
  "& svg": {
    position: "relative !important",
    top: "5px",
  },
}));

const Reddiv = styled("div")(({ theme }) => ({
  background: "#fc4343",
  color: "#fff",
  textAlign: "center",
  padding: "5px",
  position: "relative",
  "& svg": {
    position: "absolute",
    right: "10px",
    fontSize: "20px",
    cursor: "pointer",
  },
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const view = JSON.parse(localStorage.getItem("view"));

const ViewOrderDialog = (props) => {

  const theme = useTheme();
  const [value, setValue] = useState(view ? view : 0);
  const [openExceptionBox, setOpenExceptionBox] = useState(true);
  const [branchState, setBranchState] = useState({
    orderBranch: "",
    orderBranchSet: "",
    lineBranchID: "",
    orderProductID: "",
    // lineBranchSet: []
  });

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    refetch: false,
    orderID: "",
  });
  const [orderBranch, setOrderBranch] = useState({
    orderPrice: "",
    invoicedPrice: "",
    orderQuantity: "",
  });
  const [productDetails, setProductDetails] = useState("");
  const [preSellDetails, setPreSellDetails] = useState("");
  const [shippitDetails, setShippitDetails] = useState([]);
  const [showActive, setShowActive] = useState(false);
  const [showPricePolicy, setShowPricePolicy] = useState(false);
  const [newStockArr, setNewStockArr] = useState([]);
  const [orderStatus, setOrderStatus] = useState(false);
  const [stockLists, setStockLists] = useState([]);
  const [urgentException, setUrgentException] = useState(false);
  const [urgentReview, setUrgentReview] = useState(false);

  const [showOrderBranchChange, setShowOrderBranchChange] = useState(false);
  const [showProductBranchChange, setShowProductBranchChange] = useState(false);
  const [branchLoading, setBranchLoading] = useState(false);
  const [keyLoading, setKeyLoading] = useState(false);
  const [soubaLoading, setSoubaLoading] = useState(false);

  const [productBranchLoading, setProductBranchLoading] = useState(false);
  const [showAssignBranchDialog, setShowAssignBranchDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [currentAssignedOn, setCurrentAssignedOn] = useState("");

  const [acceptStatus, setAcceptStatus] = useState("");
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [openSoubaDialog, setOpenSoubaDialog] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [manifestLoader, setManifestLoader] = useState(false);

  const [showInvoicePriceEdit, setShowInvoicePriceEdit] = useState(false);
  const [showOrderPriceEdit, setShowOrderPriceEdit] = useState(false);
  const [showOrderQuantityEdit, setShowOrderQuantityEdit] = useState(false);
  const [orderBranchPriceLoading, setOrderBranchPriceLoading] = useState(false);
  const [invoiceBranchPriceLoading, setInvoiceBranchPriceLoading] =
    useState(false);
  const [orderBranchQuantityLoading, setOrderBranchQuantityLoading] =
    useState(false);
  const [multipleStockName, setMultipleStockName] = useState("");

  const [auditValues, setAuditValues] = useState("");
  const [auditLoading, setAuditLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [shippitOrder, setShippitOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [keyLoadingAddress, setKeyLoadingAddress] = useState(false);
  //const [openProcessDialog, setOpenProcessDialog] = useState(false);
  // const [openCancelProductDialog, setOpenCancelProductDialog] = useState(false);

  useEffect(() => {
    props.sendDetails(dialogDetails);

    if (props.viewDetails) {
      //when props details is loaded the get shippit detail api is called
      let newShippingDate = moment(props.viewDetails.shippingDate);
      let newCreatedDate = moment(props.viewDetails.order_created);
      let currDate = moment();

      let checkhours = newShippingDate.diff(newCreatedDate, "hours");

      let checkReview = currDate.diff(newCreatedDate, "hours");

      if (
        isNaN(checkReview) === false &&
        checkReview !== null &&
        checkReview > 48 &&
        props.viewDetails.order_status === "Received"
      ) {
        setUrgentReview(true);
      } else {
        setUrgentReview(false);
      }

      if (
        // isNaN(checkhours) === false &&
        // checkhours !== null &&
        // checkhours < 48
        props.viewDetails.isUrgentOrder === 1
      ) {
        setUrgentException(true);
      } else {
        setUrgentException(false);
      }

      props.viewDetails?.orderProduct?.map((order, index) => {
        if (order.stock?.active === "N") {
          setShowActive(true);
        }
        if (order.price_policy_of_stock?.length > 0) {
          setShowPricePolicy(true);
        }
        if (index === 0) {
          var newStocks = order.stock_on_hand;
          setStockLists(newStocks);
        }
      });
    }
  }, [props, dialogDetails]);

  useEffect(() => {
    var allStock = [];
    if (props.viewDetails.orderProduct) {
      props.viewDetails.orderProduct.map(
        (product, index) =>
          product.assignToBranch !== null &&
          product.assignToBranch?.map((sto, index2) => {
            allStock.push(sto);
          })
      );
      // console.log('allStock',allStock.length)
      if (allStock.length === 1) {
        setMultipleStockName(allStock[0].branch.branchName);
      }
      // console.log('all stock', allStock);

      if (allStock.length > 1) {
        // allStock.reduce((prev, curr) => {
        //   if(prev !== undefined){
        //     console.log('prev', prev);
        //     console.log('curr', curr);

        //   if (prev?.branch.erpBranchID !== curr?.branch.erpBranchID) {

        //     setMultipleStockName("Multiple Branch");
        //   } else {
        //     setMultipleStockName(prev.branch.branchName);
        //   }
        //   }
        // });
        allStock.map((stock, index, arr) => {
          const prev = arr[index - 1];
          if (prev?.branch.erpBranchID && stock?.branch.erpBranchID !== prev.branch.erpBranchID) {
            setMultipleStockName("Multiple Branch");
          } else {
            setMultipleStockName(stock?.branch.branchName);
          }
        });
      }
    }
  }, [props.viewDetails.orderProduct]);

  useEffect(() => {
    if (productDetails.stock_on_hand) {
      setNewStockArr(productDetails.stock_on_hand);
    }
  }, [productDetails.stock_on_hand]);

  const handleRefetch = () => {
    setDialogDetails({
      open: true,
      refetch: true,
      orderID: props.viewDetails.orderID,
    });
    setTimeout(() => {
      setDialogDetails({
        open: true,
        refetch: false,
        orderID: "",
      });
    }, 100);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeOrderBranch = (e) => {
    var newValue = "";
    stockLists.map((stock) => {
      if (stock?.new_system_branch.erpBranchID === e.target.value) {
        newValue = stock;
      }
    });
    setBranchState({
      ...branchState,
      orderBranch: e.target.value,
      orderBranchSet: newValue,
    });
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  console.log("shippitDetails", shippitDetails);

  useEffect(() => {
    if (props.viewDetails.newSystemOrderID) {
      getShippitDialog();
    }
  }, [props.viewDetails.newSystemOrderID]);

  useEffect(() => {
    if (props.viewDetails.newSystemOrderID) {
      //getShippitDialog();
      const intervalId = setInterval(getShippitDialog, 30 * 1000); // Fetch data every 60 seconds
      return () => clearInterval(intervalId);
    }
  }, [props.viewDetails.newSystemOrderID]);

  // useEffect(() => {
  //   if (props.viewDetails.newSystemOrderID && props.viewDetails.order_status === "Accepted" && props.viewDetails.order_status !== "Cancelled") {
  //     // Call the getShippitDialog function to fetch initial data
  //     //getShippitDialog();
  //     console.log("shippitDetails11111", shippitDetails[shippitDetails.length - 1]?.state)

  //     if (shippitDetails[shippitDetails.length - 1]?.state !== undefined && shippitDetails[shippitDetails.length - 1]?.state === "ready_for_pickup") {

  //     }
  //     else {
  //       var intervalId = setInterval(getShippitDialog, 30 * 1000);
  //       console.log("return--", intervalId);
  //       return () => clearInterval(intervalId);

  //     }

  //   }
  // }, [props.viewDetails.newSystemOrderID, props.viewDetails.order_status, shippitDetails]);

  const getShippitDialog = () => {
    setLoading(true);
    httpclient
      .get(`new-system/netsuite/exceptional-report/shippit/${props.viewDetails.newSystemOrderID}`)
      .then(({ data }) => {
        if (data) {
          setShippitDetails(data.data);
          setLoading(false);
        } else {
          console.log("Error!");
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        setLoading(false);
      });
  };

  const changeOrderBranch = () => {
    // var checkOrderStatus = false;

    // props.viewDetails?.orderProduct.map((product, index) => {
    //   product.stock_on_hand?.map((stock) => {
    //     if (stock?.new_system_branch.erpBranchID === branchState.orderBranch) {
    //       if (Number(product.quantity) > stock?.actualSOH) {
    //         checkOrderStatus = true;
    //       } else {
    //         checkOrderStatus = false;
    //       }
    //     }
    //   });
    // });

    //console.log("checkorder status", checkOrderStatus);

    // if (checkOrderStatus === true) {
    //   setShowAssignBranchDialog(true);
    //   setCurrentAssignedOn("Order");
    // } else {
    setBranchLoading(true);
    httpclient
      .post(`new-system/netsuite/order/update-location`, {
        orderNo: props.viewDetails.newSystemOrderNumber,
        branchId: branchState.orderBranch,
      })
      .then(({ data }) => {
        if (data.success) {
          setBranchLoading(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);

          //Refetching the data
          handleRefetch();

          // Order and Product Detail Edit Boxes set to false
          setProductDetails("");
          setShowOrderBranchChange(false);
          setShowProductBranchChange(false);
        } else {
          setBranchLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });
    //}
  };

  //Change Branch on Product Detail
  const handleChangeLineBranch = (e, id) => {
    setBranchState({
      ...branchState,
      lineBranchID: e.target.value,
      orderProductID: id,
    });
  };

  const changeBranch = () => {

    // var checkConfirm = false;
    // newStockArr.map((stock) => {
    //   if (stock?.new_system_branch.erpBranchID === branchState.lineBranchID) {
    //     if (Number(productDetails.quantity) > stock?.actualSOH) {
    //       return (checkConfirm = true);
    //     }
    //   }
    // });

    // if (checkConfirm === true) {
    //   setShowAssignBranchDialog(true);
    //   setCurrentAssignedOn("Product");
    // } else {
    setCurrentAssignedOn("");
    setProductBranchLoading(true);
    httpclient
      .post(
        `new-system/netsuite/order/line-update-location`,
        {
          orderNo: props.viewDetails.newSystemOrderNumber,
          orderProductId: branchState.orderProductID,
          branchId: branchState.lineBranchID,
        }
      )
      .then(({ data }) => {
        if (data.success) {
          setProductBranchLoading(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          handleRefetch();
          // Order and Product Detail Edit Boxes set to false
          setValue(1);
          setProductDetails("");
          setShowOrderBranchChange(false);
          setShowProductBranchChange(false);
        } else {
          setProductBranchLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.message);
        }
      });
    //}
  };
  //Change Branch on Product Detail

  const sendAssignBranch = (call) => {
    if (call.open === false) {
      setShowAssignBranchDialog(false);
      setCurrentAssignedOn("");
    }
    if (call.success === true) {
      currentAssignedOn === "Product"
        ? setProductBranchLoading(true)
        : setBranchLoading(true);
      currentAssignedOn === "Product"
        ? httpclient
          .post(
            `new-system/order-product/reassign-order/${branchState.orderProductID}`,
            {
              branchID: branchState.lineBranchID,
            }
          )
          .then(({ data }) => {
            if (data.success) {
              setProductBranchLoading(false);
              setOpen(true);
              setMessageState("success");
              setMessage(data.message);
              handleRefetch();
              // Order and Product Detail Edit Boxes set to false
              setValue(1);
              setCurrentAssignedOn("");
              setProductDetails("");
              setShowOrderBranchChange(false);
              setShowProductBranchChange(false);
            } else {
              setProductBranchLoading(false);
              setOpen(true);
              setMessageState("error");
              setMessage(data.error);
            }
          })
        : httpclient
          .post(
            `new-system/order/reassign-order/${props.viewDetails.orderID}`,
            {
              branchID: branchState.orderBranch,
            }
          )
          .then(({ data }) => {
            if (data.success) {
              setBranchLoading(false);
              setOpen(true);
              setMessageState("success");
              setMessage(data.message);

              //Refetching the data
              handleRefetch();

              // Order and Product Detail Edit Boxes set to false
              setProductDetails("");
              setShowOrderBranchChange(false);
              setShowProductBranchChange(false);
            } else {
              setBranchLoading(false);
              setOpen(true);
              setMessageState("error");
              setMessage(data.error);
            }
          });
    }
  };

  const changeOrderPrice = (id) => {
    setOrderBranchPriceLoading(true);
    httpclient
      .post(`/new-system/order-product/update-price/${id}`, {
        price: orderBranch.orderPrice,
      })
      .then(({ data }) => {
        if (data.success) {
          setOrderBranchPriceLoading(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);

          //Refetching the data
          handleRefetch();

          // Order and Product Detail Edit Boxes set to false
          setValue(1);
          setProductDetails("");
          setShowOrderPriceEdit(false);
        } else {
          setOrderBranchPriceLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
  };

  const changeInvoicePrice = (id) => {
    setInvoiceBranchPriceLoading(true);
    httpclient
      .post(
        `new-system/netsuite/order-product/update-invoice-price/${props.viewDetails.newSystemOrderID}`,
        {
          price: orderBranch.invoicedPrice,
          productID: id,
        }
      )
      .then(({ data }) => {
        if (data.success) {
          setInvoiceBranchPriceLoading(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);

          //Refetching the data
          handleRefetch();

          // Order and Product Detail Edit Boxes set to false
          setValue(1);
          setProductDetails("");
          setShowInvoicePriceEdit(false);
        } else {
          setInvoiceBranchPriceLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
  };

  // const changeOrderQuantity = (id) => {
  //   setOrderBranchQuantityLoading(true);
  //   httpclient.post(`/new-system/order-product/update-quantity/${id}`, {
  //     quantity: orderBranch.orderQuantity
  //   }).then(({ data }) => {
  //     if (data.success) {
  //       setOrderBranchQuantityLoading(false);
  //       setOpen(true);
  //       setMessageState("success");
  //       setMessage(data.message);

  //       //Refetching the data
  //       handleRefetch();

  //       // Order and Product Detail Edit Boxes set to false
  //       setValue(1);
  //       setProductDetails("");
  //       setShowOrderQuantityEdit(false);
  //     } else {
  //       setOrderBranchQuantityLoading(false);
  //       setOpen(true);
  //       setMessageState("error");
  //       setMessage(data.error);
  //     }
  //   })
  // };

  const changeOrderQuantity = (id) => {
    setOrderBranchQuantityLoading(true);
    httpclient
      .post(`/new-system/order-product/update-quantity/${id}`, {
        quantity: orderBranch.orderQuantity,
      })
      .then(({ data }) => {
        if (data.success) {
          setOrderBranchQuantityLoading(false);
          setOpen(true);
          if (orderBranch.orderQuantity > productDetails.quantity) {
            setMessageState("warning");
            setMessage(
              "For now the order can't be fulfilled by DSCO, you can change back to the original value later"
            );
          } else {
            setMessageState("success");
            setMessage(data.message);
          }

          //Refetching the data
          handleRefetch();

          // Order and Product Detail Edit Boxes set to false
          setValue(1);
          setProductDetails("");
          setShowOrderQuantityEdit(false);
        } else {
          setOrderBranchQuantityLoading(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
  };

  // const handleCancelProductDialogue = () => {
  //   setOpenCancelProductDialog(true);
  // };
  // const sendToCancelProduct = (call, reason, reasonCode) => {
  //   if (call.open === false) {
  //     setOpenCancelProductDialog(false);
  //   }
  //   if (call.success === true) {
  //     setKeyLoading(true);

  //     httpclient
  //       .post(
  //         `https://uba.synccare.com.au/php/api/new-system/order/cancel-order-item`,
  //         {
  //           orderID: props.viewDetails.newSystemOrderID,
  //           productID: [productDetails.orderproductID],
  //           reason: reason,
  //           reasonCode: reasonCode,
  //         }
  //       )
  //       .then(({ data }) => {
  //         if (data.success) {
  //           setOpen(true);
  //           setMessageState("success");
  //           //setOpenActiveDialog(false);
  //           setMessage(data.message);
  //           setKeyLoading(false);
  //           //Refetching the data
  //           handleRefetch();

  //           //setProductDetails("");
  //         } else {
  //           setKeyLoading(false);
  //           setOpen(true);
  //           setMessageState("error");
  //           setMessage(data.error);
  //         }
  //       });
  //   }
  // };

  const handleCancelDialogue = () => {
    setOpenCancelDialog(true);
  };

  const sendToCancel = (call, reason, reasonCode) => {
    if (call.open === false) {
      setOpenCancelDialog(false);
    }
    if (call.success === true) {
      setKeyLoading(true);
      httpclient
        .post(`new-system/netsuite/order/cancel-order`, {
          orderID: [props.viewDetails.newSystemOrderID],
          companyID: [props.viewDetails.companyID],
          reason: reason,
          reasonCode: reasonCode,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            //setOpenActiveDialog(false);
            setMessage(data.message);
            setKeyLoading(false);
            //Refetching the data
            handleRefetch();

            //setProductDetails("");
          } else {
            setKeyLoading(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
          }
        });
    }
  };

  const handleRefetchSouba = () => {
    setOpenSoubaDialog(true);
  };

  const sendToSouba = (call) => {
    if (call.open === false) {
      setOpenSoubaDialog(false);
    }
    if (call.success === true) {
      setSoubaLoading(true);
      httpclient
        .get(`netsuite/v1/refetchNetsuiteOrderNumber?netsuiteID=${props.viewDetails.netsuiteOrderID}`)
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setSoubaLoading(false);
            handleRefetch();

            //setProductDetails("");
          } else {
            setSoubaLoading(false);
            setOpen(true);
            setMessageState("error");
            setMessage(data.error || data.message);
          }
        });
    }
  };

  const handleMarkAsShipped = () => {
    setManifestLoader(true);
    httpclient
      // .post(`new-system/order/cancel-order`, {
      //   orderID: [props.viewDetails.newSystemOrderID],
      //   companyID: [props.viewDetails.companyID],
      //   reason: reason,
      //   reasonCode: reasonCode,
      // })
      .then(({ data }) => {
        if (data.success) {
          setManifestLoader(false);
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);

          //Refetching the data
          handleRefetch();
        } else {
          setManifestLoader(false);
          setOpen(true);
          setMessageState("error");
          setMessage(data.error);
        }
      });
  };

  // const handleManualProcess = () => {
  //   setOpenProcessDialog(true);
  // };

  // const sendToProcess = (call) => {

  //   if (call.open === false) {
  //     setOpenProcessDialog(false);
  //   }
  //   if (call.success === true) {
  //     setKeyLoading1(true);
  //     httpclient
  //       .post(
  //         `https://uba.synccare.com.au/php/api/new-system/order/ignore-order`,
  //         {
  //           orderID: [props.viewDetails.orderID],
  //         }
  //       )
  //       .then(({ data }) => {
  //         if (data.success) {
  //           setOpen(true);
  //           setMessageState("success");
  //           //setOpenActiveDialog(false);
  //           setMessage(data.message);
  //           setKeyLoading1(false);
  //           //Refetching the data
  //           handleRefetch();

  //           //setProductDetails("");
  //         } else {
  //           setKeyLoading1(false);
  //           setOpen(true);
  //           setMessageState("error");
  //           setMessage(data.error);
  //         }
  //       });
  //   }
  // };
  // console.log("------------", props.viewDetails.newSystemOrderID)
  //console.log("------------", productDetails)
  // console.log("branch >>>", branchState);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleView = (row) => {
    setProductDetails(row);
  };
  const showPreSell = (row) => {
    setPreSellDetails(row);

  };
  // const handleView1 = (row) => {
  //   setShippitDetails(row);
  // };

  useEffect(() => {
    console.log("PRESELL", preSellDetails);
  }, [preSellDetails]);

  const handleAccept = () => {
    setAcceptStatus("accept");
    setOpenStatusDialog(true);
  };

  const handleReject = (row) => {
    setAcceptStatus("reject");
    setOpenStatusDialog(true);
  };

  const sendChangeOrder = (call, reason, reasonCode) => {
    if (call.open === false) {
      setAcceptStatus("");
      setOpenStatusDialog(false);
    }
    if (call.success === true) {
      setButtonLoader(true);
      const apipoint =
        // props.viewDetails.orderType === "Bunnings" && acceptStatus === "accept"
        //   ? "/new-system/netsuite/order/bunnings-accept"
        //   : 
        "/new-system/netsuite/order/update-status";
      httpclient
        .post(apipoint, {
          orderID: [props.viewDetails.orderID],
          companyID: [props.viewDetails.companyID],
          status: acceptStatus === "accept" ? "Accepted" : "Rejected",
          reason: reason,
          reasonCode: reasonCode,
          fullfillment_status:
            acceptStatus === "reject" ? "0" : call.fullfillment,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);

            //Accept and Reject setting back to initail state
            setAcceptStatus("");
            setButtonLoader(false);
            //Refetch the data
            handleRefetch();
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.error);
            setButtonLoader(false);
            setAcceptStatus("");
          }
        })
        .catch((err) => {
          setOpen(true);
          setMessageState("error");
          setMessage("Error: Server error");
          setButtonLoader(false);
          setAcceptStatus("");
        });
    }
  };

  const handleBack = () => {
    setProductDetails("");
    setBranchState({
      ...branchState,
      lineBranchID: "",
      orderProductID: "",
    });
    setOrderBranch({
      ...orderBranch,
      orderQuantity: "",
      orderPrice: "",
      invoicedPrice: "",
    });
    setShowOrderPriceEdit(false);
    setShowInvoicePriceEdit(false);
    setShowOrderQuantityEdit(false);
  };
  const handlePreSellBack = () => {
    setPreSellDetails("");
  };

  const handleViewAuditTrial = () => {
    setAuditLoading(true);
    httpclient(
      `new-system/netsuite/exceptional-report/order-log/${props.viewDetails.newSystemOrderNumber}`
    ).then(({ data }) => {
      if (data) {
        setAuditValues(data);
        setAuditLoading(false);
      }
    });
  };

  const handleBackFromAudit = () => {
    setAuditValues("");
  };

  const sendReset = (call) => {
    if (call.open === false) {
      setShowEditDialog(false);
    }
    if (call.success === true) {
      setKeyLoadingAddress(true);
      httpclient
        .post(`new-system/netsuite/order/update-delivery-address`, {
          orderID: props.viewDetails.newSystemOrderID,
          deliveryFirstName: call.deliveryFirstName,
          deliveryLastName: call.deliveryLastName,
          deliveryPhone: call.deliveryPhone,
          deliveryStreet: call.deliveryStreet,
          deliveryCity: call.deliveryCity,
          deliveryState: call.deliveryState,
          deliveryPostCode: call.deliveryPostCode,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpen(true);
            setMessageState("success");
            setMessage(data.message);
            setTimeout(() => {
              handleRefetch();
            }, 3000);
            setKeyLoadingAddress(false);
          } else {
            setOpen(true);
            setMessageState("error");
            setMessage(data.message);
            setKeyLoadingAddress(false);
          }
        });
    }
  };

  function accept(props) {
    if (
      props.viewDetails.company.multiLoc_desp_per_po === "0" &&
      props.viewDetails.orderProduct.some(
        (product) => product.assignToBranch?.length > 1
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  const productColumns = [
    ...(props.viewDetails.order_status === "Accepted" ||
      props.viewDetails.order_status === "Pre-Sales" ||
      props.viewDetails.order_status === "Rejected" ||
      props.viewDetails.order_status === "Cancelled" ||
      props.viewDetails.order_status === "Ignored"
      ? [{ id: "status", name: "Status" }]
      : []),
    { id: "stockCode", name: "Stock Code" },
    { id: "stockNameNetsuite", name: "Stock Name" },
    ...(props.viewDetails.orderType === "TW"
      ? [{ id: "tpw_reference", name: "TPW Reference" }]
      : []),
    { id: "quantity", name: "Qty" },
    { id: "unitPrice", name: "Import PO Price" },
    { id: "bestPrice", name: "UBA Lowest Price" },
    { id: "invoicedPrice", name: "UBA Inv Price" },
    { id: "shippingFee", name: "Shipping Fee" },
    { id: "assignedBranchNetsuite", name: "Location" },
    { id: "reason", name: "Exception Reason" },

    { id: showActive ? "active" : null, name: showActive ? "Active" : null },
    // ...(props.viewDetails.order_status === "Accepted"
    //   ? [{ id: "cancel", name: "Cancel" }]
    //   : []),
  ];

  const productExceptionalColumns = [
    { id: "checkColumn", name: "Accept" },
    { id: "checkColumn1", name: "Reject" },
    { id: "stockCode", name: "Stock Code" },
    { id: "stockNameNetsuite", name: "Stock Name" },
    { id: "quantity", name: "Qty" },
    { id: "unitPrice", name: "Import PO Price" },
    { id: "bestPrice", name: "UBA Lowest Price" },
    { id: "invoicedPrice", name: "UBA Inv Price" },
    { id: "shippingFee", name: "Shipping Fee" },
    { id: "assignedBranchNetsuite", name: "Location" },
    { id: "reason", name: "Exception Reason" },
    { id: showActive ? "active" : null, name: showActive ? "Active" : null },
  ];

  const productExceptionalColumnsTW = [
    { id: "stockCode", name: "Stock Code" },
    { id: "stockNameNetsuite", name: "Stock Name" },
    { id: "tpw_reference", name: "TPW Reference" },
    { id: "quantity", name: "Qty" },
    { id: "unitPrice", name: "Import PO Price" },
    { id: "bestPrice", name: "UBA Lowest Price" },
    { id: "invoicedPrice", name: "UBA Inv Price" },
    { id: "shippingFee", name: "Shipping Fee" },
    { id: "assignedBranchNetsuite", name: "Location" },
    { id: "reason", name: "Exception Reason" },
    { id: "confirm", name: "Accept/Reject" },

    { id: showActive ? "active" : null, name: showActive ? "Active" : null },
  ];

  const preSellColumns = [
    { id: "stockCode", name: "Stock Code" },
    { id: "quantity", name: "Qty" },
    { id: "containerPoNumber", name: "Container PO Number" },
    { id: "containerName", name: "Container Name" },
    // { id: "containerEstimateArrivalDate", name: "Estimated Arrival Date" },
    { id: "customerDispatchDate", name: "Customer Dispatch Date" },
  ];

  const preSellContainerColumns = [
    { id: "item", name: "Item" },
    { id: "containerPoNumber", name: "Container PO No." },
    { id: "incomingQty", name: "Incomming Qty" },
    { id: "availabilityQty", name: "Availability Qty" },
    { id: "reservedQty", name: "Reserved Qty" },
  ];

  const shippitColumns = [
    { id: "trackingID", name: "Shippit ID" },
    { id: "manifest_id", name: "Manifest ID" },
    { id: "newSystemOrderID", name: "Order ID" },
    { id: "courier_job_id", name: "Tracking ID" },
    { id: "courier_name", name: "Courier Name" },
    { id: "state", name: "State" },
    { id: "labelPDF", name: "Label PDF" },
    { id: "manifestPDF", name: "Manifest PDF" },
    { id: "shippitModifiedDate", name: "Shippit Modified Date" },
    // { id: "user_attributes_email", name: "User Email" },
    {
      id: "delivery_address",
      name: "Delivery Details",
      concatenate: true,
    },
  ];

  const shippitColumns1 = [
    // { id: "trackingID", name: "Shippit ID" },
    // { id: "manifest_id", name: "Manifest ID" },
    { id: "newSystemOrderID", name: "Order ID" },
    { id: "trackingID", name: "Tracking ID" },
    { id: "courier_name", name: "Courier Name" },

    //{ id: "state", name: "State" },

    // { id: "user_attributes_email", name: "User Email" },
    { id: "labelPDF", name: "Label PDF" },
    {
      id: "delivery_address",
      name: "Delivery Details",
      concatenate: true,
    },
  ];

  const shippitColumns2 = [
    // { id: "trackingID", name: "Shippit ID" },
    // { id: "manifest_id", name: "Manifest ID" },
    { id: "newSystemOrderID", name: "Order ID" },
    { id: "trackingID", name: "Tracking ID" },
    { id: "courier_name", name: "Courier Name" },

    //{ id: "state", name: "State" },

    // { id: "user_attributes_email", name: "User Email" },
    {
      id: "delivery_address",
      name: "Delivery Details",
      concatenate: true,
    },
    { id: "labelPDF", name: "Label PDF" },
  ];

  const auditColumns = [
    { id: "sn", name: "SN" },
    { id: "log", name: "Log" },
    { id: "date", name: "Date" },
    { id: "actionBy", name: "Action By" },
  ];

  const StockOnHandColumn = [
    { id: "sn", name: "SN" },
    { id: "branchName", name: "Branch Name" },
    { id: "actualSOH", name: "Available Stock" },
    { id: "comittedStock", name: "Committed Stock" },
    { id: "totalInStock", name: "Total Stock" },
  ];

  const handledownloadPDF = () => {
    const { viewDetails } = props;

    if (viewDetails.isInvoiceReady === 1 && viewDetails.invoiceUrl) {
      const link = document.createElement("a");
      link.href = viewDetails.invoiceUrl;
      link.target = "_blank";
      link.download = "invoice.pdf";
      link.click();
    }
  };


  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>{`View Order Details (${props.viewDetails.newSystemOrderNumber || ""
            })`}</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent style={{ margin: "0", padding: "0" }}>
            <AppBarTabs position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label="ORDER"
                  icon={
                    props.viewDetails.isPresellOrder === 1 &&
                    (props.viewDetails.isPresellOrderReceipted === 1 ? presellOrderContent1 :
                      presellOrderContent)
                  }
                  {...a11yProps(0)}
                />

                <Tab label="Product" {...a11yProps(1)} />
                <Tab label="Shipping" {...a11yProps(2)} />
                {(props.viewDetails.orderType === "TW" &&
                  (props.viewDetails.isPresellOrder === 1 || props.viewDetails.isLimboOrderWithPreSales === 1)) && (
                    <Tab label="Pre-Sales" {...a11yProps(3)} />
                  )}
              </Tabs>
            </AppBarTabs>

            {urgentReview && value === 0 && openExceptionBox ? (
              <Reddiv>
                <span>Urgent Review Required</span>
                <Close onClick={() => setOpenExceptionBox(false)} />
              </Reddiv>
            ) : urgentException && value === 0 && openExceptionBox ? (
              <Reddiv>
                <span>Urgent Order</span>
                <Close onClick={() => setOpenExceptionBox(false)} />
              </Reddiv>
            ) : props.viewDetails.order_status === "Cancelled" &&
              value === 0 &&
              openExceptionBox ? (
              <Reddiv>
                <span>This order has been cancelled.</span>
                <Close onClick={() => setOpenExceptionBox(false)} />
              </Reddiv>
            ) : props.viewDetails.order_status === "Rejected" &&
              value === 0 &&
              openExceptionBox ? (
              <Reddiv>
                <span>This order has been rejected.</span>
                <Close onClick={() => setOpenExceptionBox(false)} />
              </Reddiv>
            ) : props.viewDetails.reportType === "exceptionalOrder" &&
              value === 0 &&
              openExceptionBox ? (
              <Reddiv>
                <span>This order is an Exception.</span>
                <Close onClick={() => setOpenExceptionBox(false)} />
              </Reddiv>
            ) : null}

            {/* {props.viewDetails.reportType === "exceptionalOrder" &&
                value === 0 &&
                openExceptionBox && (
                  <Reddiv>
                    <span>This order is an Exception.</span>
                    <Close onClick={() => setOpenExceptionBox(false)} />
                  </Reddiv>
                )} */}

            {/* Tab 1 starts*/}
            <TabPanel value={value} index={0} dir={theme.direction}>
              {auditValues ? (
                <>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <h3>Audit Trial</h3>
                    <Button onClick={handleBackFromAudit}>
                      <KeyboardArrowLeft
                        fontSize="small"
                        sx={{ marginRight: "5px" }}
                      />{" "}
                      <span>Back</span>
                    </Button>
                  </Box>

                  <BasicTable2 columns={auditColumns} rows={auditValues} />
                </>
              ) : (
                <Box p={3}>
                  <Grid container spacing={2}>
                    {/* Left Side */}
                    <Grid item xs={12} md={6}>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>PO Number</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {/* {props.viewDetails.newSystemOrderNumber || "-"} */}
                          {props.viewDetails.company.erpAccountCustomerID === 2386 ?
                            `${props.viewDetails.orderProduct[0]?.newSystemProductId}  (${props.viewDetails.newSystemOrderNumber})`
                            :
                            props.viewDetails.newSystemOrderNumber || "-"
                          }
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Netsuite Order Number</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          <span
                            style={{
                              color: props.viewDetails.netsuiteOrderNumber
                                ? "inherit"
                                : "red",
                            }}
                          >
                            {props.viewDetails.netsuiteOrderNumber
                              ? props.viewDetails.netsuiteOrderNumber
                              : "No Netsuite Number"}
                          </span>
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Order Total Amount</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          $
                          {(props.viewDetails.orderTotalAmount === 0
                            ? props.viewDetails.totalOrder
                            : props.viewDetails.orderTotalAmount) || "-"}
                        </Values>
                      </FlexContent>

                      {/* <FlexContent>
                        <FlexInnerTitle>
                          <span>Customer Email</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.newSystemCustomerEmail || "-"}
                        </Values>
                      </FlexContent> */}

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Order Type</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.orderType || "-"}</Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>
                            {props.viewDetails.orderProduct.some(
                              (product) => product.reasonCode === "6"
                            )
                              ? "Order Status"
                              : props.viewDetails.order_status === "Accepted" ||
                                props.viewDetails.order_status === "Pre-Sales" ||
                                props.viewDetails.order_status === "Rejected" ||
                                props.viewDetails.order_status ===
                                "Cancelled" ||
                                props.viewDetails.order_status === "Ignored"
                                ? "Order Status"
                                : "Order Confirmation"}
                          </span>{" "}
                          <span> : </span>
                        </FlexInnerTitle>
                        <Box ml={2}>
                          {props.viewDetails.orderProduct.some(
                            (product) => product.reasonCode === "6"
                          ) ? (
                            "Inactive"
                          ) : props.viewDetails.order_status === "Accepted" ||
                            props.viewDetails.order_status === "Pre-Sales" ||
                            props.viewDetails.order_status === "Rejected" ||
                            props.viewDetails.order_status === "Cancelled" ||
                            props.viewDetails.order_status === "Ignored" ? (
                            props.viewDetails.order_status === "Ignored" ? (
                              "Manually Processed"
                            ) : (
                              props.viewDetails.order_status
                            )
                          ) : (
                            <>
                              <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => handleAccept()}
                                disabled={
                                  props.viewDetails.netsuiteOrderNumber === null ||
                                  buttonLoader ||
                                  accept(props) ||
                                  !props.viewDetails.orderProduct.some(
                                    (product) =>
                                      product.reasonCode === "3" ||
                                      product.reasonCode === "4" ||
                                      product.reasonCode === "5" ||
                                      product.reasonCode === "12" ||
                                      product.reasonCode === ""
                                  )
                                  // || props.viewDetails.orderProduct.some((product) => product.reasonCode === "2")
                                }
                              >
                                Accept{" "}
                                <Check
                                  fontSize="small"
                                  style={{ marginLeft: "5px", top: "0" }}
                                />
                              </Button>
                              <Button
                                color="error"
                                size="small"
                                variant="contained"
                                onClick={() => handleReject()}
                                style={{ marginLeft: "10px" }}
                                disabled={
                                  props.viewDetails.netsuiteOrderNumber === null ||
                                  buttonLoader
                                }
                              >
                                Reject{" "}
                                <Clear
                                  fontSize="small"
                                  style={{ marginLeft: "5px", top: "0" }}
                                />
                              </Button>
                              <></>
                              {props.viewDetails.netsuiteOrderNumber === null && (
                                <Tooltip title={"Waiting for Netsuite"}>
                                  <IconButton>
                                    <RestartAlt style={{ color: "#9d182d" }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {buttonLoader && (
                                <CircularProgress
                                  style={{
                                    marginLeft: "10px",
                                    position: "relative",
                                    top: "5px",
                                    width: "25px",
                                    height: "25px",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Box>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Cancel Status</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.cancelStatus || "-"}</Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Order Pending</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.pending === 1 ? (
                            <Check color="primary" />
                          ) : (
                            <Clear color="primary" />
                          )}{" "}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Pending Process</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.pendingProcess === 1 ? (
                            <Check color="primary" />
                          ) : (
                            <Clear color="primary" />
                          )}{" "}
                        </Values>
                      </FlexContent>
                      {props.viewDetails.order_status !== "Ignored" && (
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Invoiced?</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {props.viewDetails.invoiceStatus === "invoiced" ||
                              props.viewDetails.invoiceStatus === "Invoiced"
                              ? "Yes"
                              : "No"}{" "}
                          </Values>
                        </FlexContent>
                      )}
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Customer Name</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.company.erpAccountName || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Consumer Name</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.billToName || "-"}</Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Bill To Email</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.billToEmail || "-"}</Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Delivery Details</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.deliveryDetail ? props.viewDetails.deliveryDetail.fullAddress : shippitDetails[0] === undefined ? <strong style={{ color: "red" }}>Missing [Urgent Review Required]</strong> : `${shippitDetails[0]?.delivery_address || ""} ${shippitDetails[0]?.delivery_suburb || ""
                            } ${shippitDetails[0]?.delivery_postcode || ""
                            }`.trim() || "-"}

                          {(props.viewDetails.orderType === "TW") ? (
                            <Tooltip title="Delivery Address edit feature disabled for TW Orders.">
                              <IconButton>
                                <Edit
                                  sx={{
                                    marginTop: "0px",
                                    marginRight: "0px",
                                    opacity: "0.5",
                                  }}
                                  fontSize="small"
                                  color="primary"
                                />
                              </IconButton>
                            </Tooltip>
                          ) :

                            (props.viewDetails.isReadyForCancelOrder === 1) ? (

                              <IconButton
                                onClick={() => {
                                  setShowEditDialog(true);
                                }}
                              >
                                {keyLoadingAddress ? (
                                  <CircularProgress
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      position: "relative",
                                    }}
                                  />
                                ) : (
                                  <Edit
                                    sx={{ marginTop: "0px", marginRight: "0px" }}
                                    fontSize="small"
                                    color="primary"
                                  />
                                )}
                              </IconButton>
                            ) : shippitDetails && shippitDetails.some(
                              (shipment) => shipment.state === "processing"
                            ) ? (
                              <Tooltip title="Wait! The process is now currently being executed.">
                                <IconButton>
                                  <Edit
                                    sx={{
                                      marginTop: "0px",
                                      marginRight: "0px",
                                      opacity: "0.5",
                                    }}
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (props.viewDetails.order_status === "Cancelled") ? (
                              <Tooltip title="The order has been cancelled">
                                <IconButton>
                                  <Edit
                                    sx={{
                                      marginTop: "0px",
                                      marginRight: "0px",
                                      opacity: "0.5",
                                    }}
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="The state of shipment doesnot permit delivery details change.">
                                <IconButton>
                                  <Edit
                                    sx={{
                                      marginTop: "0px",
                                      marginRight: "0px",
                                      opacity: "0.5",
                                    }}
                                    fontSize="small"
                                    color="primary"
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                        </Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Ready to fulfill</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {!(
                            props.viewDetails.order_status === "Accepted" ||
                            props.viewDetails.order_status === "Pre-Sales" ||
                            props.viewDetails.order_status === "Rejected" ||
                            props.viewDetails.order_status === "Cancelled"
                          ) &&
                            props.viewDetails.reportType ===
                            "exceptionalOrder" ? (
                            <Tooltip title="Not ready for fulfillment">
                              <Clear color="error" />
                            </Tooltip>
                          ) : (
                            props.viewDetails.fullfillment_status === "1" &&
                            (props.viewDetails.order_status === "Accepted" || props.viewDetails.order_status === "Pre-Sales") && (
                              <Tooltip title="This order has been accepted for fulfillment">
                                <Check color="primary" />
                              </Tooltip>
                            )
                          )}
                          {/* {props.viewDetails.fullfillment_status === "1" ? (
                            <Check color="primary" />
                          ) : (
                            <Clear color="primary" />
                          )} */}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Assigned to Branch</span> <span> : </span>
                        </FlexInnerTitle>
                        {!showOrderBranchChange ? (
                          <Box ml={2}>
                            {/* {props.viewDetails.assignToBranch?.ciBranchName} */}
                            {multipleStockName}
                            {props.viewDetails.isChangeOrderLocation === 1 ? (
                              // !(
                              //   props.viewDetails.order_status === "Accepted" ||
                              //   props.viewDetails.order_status === "Pre-Sales" ||
                              //   props.viewDetails.order_status === "Rejected" ||
                              //   props.viewDetails.order_status === "Cancelled" ||
                              //   props.viewDetails.order_status === "Ignored"
                              // ? (
                              <IconButton
                                onClick={() => setShowOrderBranchChange(true)}
                              >
                                <Edit fontSize="small" color="primary" />
                              </IconButton>
                            ) : null}
                          </Box>
                        ) : (
                          <Values>
                            <FormControl
                              style={{ width: "180px", marginBottom: "10px" }}
                            >
                              <Select
                                value={
                                  branchState.orderBranch
                                    ? branchState.orderBranch
                                    : props.viewDetails.assignToBranch?.erpBranchID
                                }
                                onChange={(e) => handleChangeOrderBranch(e)}
                                name="orderBranch"
                              >
                                <MenuItem value={""}>
                                  <em>Select Branch</em>
                                </MenuItem>
                                {stockLists.length > 0 &&
                                  stockLists.map((stock) => (
                                    <MenuItem
                                      value={stock?.new_system_branch.erpBranchID}
                                      key={stock?.new_system_branch.erpBranchID}
                                    >
                                      {stock?.new_system_branch.branchName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>

                            {branchLoading ? (
                              <CircularProgress
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginLeft: "10px",
                                  position: "relative",
                                  top: "10px",
                                }}
                              />
                            ) : (
                              <>
                                <Tooltip title="Back">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      setShowOrderBranchChange(false)
                                    }
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <ArrowBack fontSize="small" />
                                  </Button>
                                </Tooltip>

                                <Tooltip title="Change Branch">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={changeOrderBranch}
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <Done fontSize="small" />
                                  </Button>
                                </Tooltip>
                              </>
                            )}
                          </Values>
                        )}
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Tracking Url</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          <span>
                            {shippitDetails.length > 0 &&
                              (shippitDetails[shippitDetails.length - 1]
                                ?.state === "processing"
                                ? "Generating Tracking Url..."
                                : props.viewDetails.trackingUrl || "-")}
                          </span>
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Packing Instruction</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.packingInstructions || "-"}
                        </Values>
                      </FlexContent>
                    </Grid>
                    {/* Left Side */}

                    {/* Right Side */}
                    <Grid item xs={12} md={6}>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Shipping Country</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.shipping_country || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Shipping Methods</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.shipping_methods || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Shipping Store</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.shipping_store || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Shipping Type</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.shipping_type || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Get Customer Signature</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.authority_to_leave === "Yes" ? (
                            <Clear color="error" />
                          ) : (
                            <Check color="primary" />
                          )}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Total Line</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.total_items || "-"}</Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span> Customer Shipping Date</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.shippingDate !==
                            "0000-00-00 00:00:00"
                            ? props.viewDetails.shippingDate !== null
                              ? moment(props.viewDetails.shippingDate).format(
                                "ddd, MMM Do YYYY, h:mm a"
                              )
                              : ""
                            : "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Customer Exp Delivery Date</span>{" "}
                          <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.expectedDeliveryDate !==
                            "0000-00-00 00:00:00"
                            ? props.viewDetails.expectedDeliveryDate !== null
                              ? moment(
                                props.viewDetails.expectedDeliveryDate
                              ).format("ddd, MMM Do YYYY, h:mm a")
                              : "-"
                            : "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Customer Order Date</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.consumerOrderDate !==
                            "0000-00-00 00:00:00"
                            ? props.viewDetails.consumerOrderDate !== null
                              ? moment(
                                props.viewDetails.consumerOrderDate
                              ).format("ddd, MMM Do YYYY, h:mm a")
                              : "-"
                            : "-"}
                        </Values>
                      </FlexContent>

                      {/* row[col.id] ? moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm:ss a") : "-" */}

                      <FlexContent>
                        <FlexInnerTitle>
                          <span
                            style={{ color: urgentReview ? "red" : "inherit" }}
                          >
                            Customer Order Created
                          </span>{" "}
                          <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          <span
                            style={{ color: urgentReview ? "red" : "inherit" }}
                          >
                            {moment(props.viewDetails.order_created).format(
                              "ddd, MMM Do YYYY, h:mm a"
                            ) || "-"}
                          </span>
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span
                            style={{ color: urgentReview ? "red" : "inherit" }}
                          >
                            Customer Last Updated
                          </span>{" "}
                          <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          <span
                            style={{ color: urgentReview ? "red" : "inherit" }}
                          >
                            {moment(props.viewDetails.lastUpdate).format(
                              "ddd, MMM Do YYYY, h:mm a"
                            ) || "-"}
                          </span>
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          Despatch Report Generated ?<span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.isDispatchReportGenerated === 1 ? "Yes" : "No"}
                        </Values>
                      </FlexContent>

                      {props.viewDetails.orderType === "TW" && props.viewDetails.isDispatchReportGenerated === 1 && (
                        <FlexContent>
                          <FlexInnerTitle>
                            <span> Despatch Generated Date</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {props.viewDetails.dispatchReportGeneratedDate !== "" ? moment(props.viewDetails.dispatchReportGeneratedDate).format("ddd, MMM Do YYYY, h:mm a") : "-"}
                          </Values>
                        </FlexContent>
                      )}

                      {props.viewDetails.orderType === "TW" && props.viewDetails.isDispatchReportGenerated === 1 && (props.viewDetails.dispatchReportFileLink !== "" && props.viewDetails.dispatchReportFileLink !== null) && (
                        <FlexContent>
                          <FlexInnerTitle>
                            <span> Despatch Generated Link</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            <IconButton
                              href={props.viewDetails.dispatchReportFileLink}
                              download
                              target="_blank"
                            >
                              <Download color="primary" fontSize="medium" />
                            </IconButton>
                          </Values>
                        </FlexContent>
                      )}


                      {/* <FlexContent>
                        <FlexInnerTitle>
                          
                          <span>Shipment Status</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                        {props.viewDetails.shipment === "pending" ? (
                          
                        <span style={{ opacity: "0.3", fontWeight: "700" }}>
                          {props.viewDetails.shipment === "0" || props.viewDetails.shipment === "" ? "N/A" : props.viewDetails.shipment}
                        </span>
                        ):(
                          <span>
                          {props.viewDetails.shipment === "0" || props.viewDetails.shipment === "" ? "N/A" : props.viewDetails.shipment}
                          </span>
                          )}
                         </Values>
  
                      </FlexContent> */}

                      {props.viewDetails.shipment === "pending" ? (
                        <FlexContent>
                          <FlexInnerTitle>
                            <span style={{ opacity: "0", fontWeight: "700" }}>
                              Shipment Status
                            </span>{" "}
                            <span style={{ opacity: "0", fontWeight: "700" }}>
                              {" "}
                              :{" "}
                            </span>
                          </FlexInnerTitle>
                          <Values>
                            <span style={{ opacity: "0", fontWeight: "700" }}>
                              {props.viewDetails.shipment || "-"}
                            </span>
                          </Values>
                        </FlexContent>
                      ) : (
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Shipment Status</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            <span>
                              {props.viewDetails.shipment === "0" ||
                                props.viewDetails.shipment === ""
                                ? "N/A"
                                : props.viewDetails.shipment}
                            </span>
                          </Values>
                        </FlexContent>
                      )}
                      {props.viewDetails.orderType === "Bunnings" &&
                        shippitDetails.length > 0 &&
                        !(
                          shippitDetails[shippitDetails.length - 1]
                            ?.manifest_id === "" ||
                          shippitDetails[shippitDetails.length - 1]
                            ?.manifest_id === null
                        ) && (
                          <FlexContent>
                            <FlexInnerTitle>
                              <span>Bunnings Ship Status</span> <span> : </span>
                            </FlexInnerTitle>
                            <Box ml={2}>
                              {/* {props.viewDetails.orderProduct.some(
                                  (product) => product.reasonCode === "6"
                                ) ? (
                                  "Inactive"
                                ) : props.viewDetails.order_status === "Accepted" ||
                                  props.viewDetails.order_status === "Rejected" ||
                                  props.viewDetails.order_status === "Cancelled" ||
                                  props.viewDetails.order_status === "Ignored" ? (
                                  props.viewDetails.order_status === "Ignored" ? (
                                    "Manually Processed"
                                  ) : (
                                    props.viewDetails.order_status
                                  )
                                ) : ( */}
                              <>
                                <Button
                                  color="primary"
                                  size="small"
                                  variant="contained"
                                  //onClick={() => handleMarkAsShipped()}
                                  disabled={manifestLoader}
                                >
                                  Mark as shipped{" "}
                                  <DoneAll
                                    fontSize="small"
                                    style={{ marginLeft: "5px", top: "0" }}
                                  />
                                </Button>

                                <></>

                                {manifestLoader && (
                                  <CircularProgress
                                    style={{
                                      marginLeft: "10px",
                                      position: "relative",
                                      top: "5px",
                                      width: "25px",
                                      height: "25px",
                                    }}
                                  />
                                )}
                              </>
                              {/* )} */}
                            </Box>
                          </FlexContent>
                        )}

                      <FlexContent>
                        <FlexInnerTitle></FlexInnerTitle>
                        <Values
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            disabled={auditLoading}
                            onClick={handleViewAuditTrial}
                          >
                            <LockOpen
                              fontSize="small"
                              style={{ marginRight: "5px" }}
                            />{" "}
                            View Audit Trial
                          </Button>
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle></FlexInnerTitle>
                        <Values
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >

                          {props.viewDetails.isDuplicateNetsuiteNumber === 1 && (
                            <>

                              {soubaLoading ? (
                                <CircularProgress
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    marginLeft: "10px",
                                    position: "relative",
                                    top: "10px",
                                    marginBottom: "10px",
                                  }}
                                />
                              ) : (

                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleRefetchSouba()}
                                  disableElevation
                                >
                                  <SyncLock
                                    fontSize="small"
                                    style={{ marginRight: "5px" }}
                                  />
                                  Refetch SOUBA Number

                                </Button>

                              )}

                            </>
                          )}
                        </Values>
                      </FlexContent>

                      {props.viewDetails.isInvoiceReady === 1 && (props.viewDetails.bunningsInvoicePushStatusPending === 1 || props.viewDetails.bunningsInvoicePushStatusPending === 2) && props.viewDetails.orderType !== "TW" && (
                        <FlexContent>
                          <FlexInnerTitle></FlexInnerTitle>
                          <Values
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            <Button
                              size="small"
                              variant="contained"
                              onClick={handledownloadPDF}
                            >
                              <Download
                                fontSize="small"
                                style={{ marginRight: "5px" }}
                              />{" "}
                              Download Bunning Invoice
                            </Button>
                          </Values>
                        </FlexContent>
                      )}

                      {/* {props.viewDetails.order_status === "Received" &&
                          <FlexContent>
                            <FlexInnerTitle></FlexInnerTitle>
                            <Values style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                              <Button size="small"
                                variant="contained"
                                disabled={keyLoading1}
                                onClick={handleManualProcess}
                              >
                                <HourglassEmptyOutlined fontSize="small" style={{ marginRight: "5px" }} />  Manual Process
                              </Button>
                            </Values>
                          </FlexContent>
                        } */}
                      <FlexContent>
                        <FlexInnerTitle></FlexInnerTitle>
                        <Values
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          {/* {(props.viewDetails.isSkuLogistics === 1 || (props.viewDetails.orderType === "TW" && props.viewDetails.order_status == "Pre-Sales" && props.viewDetails.isSkuLogistics === null)) &&
                            props.viewDetails.order_status !== "Cancelled" ? (
                            <>
                              
                              {keyLoading ? (
                                <CircularProgress
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    marginLeft: "10px",
                                    position: "relative",
                                    top: "10px",
                                  }}
                                />
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleCancelDialogue()}
                                    disableElevation
                                  >
                                    Cancel this order
                                    <HighlightOff
                                      fontSize="small"
                                      style={{ marginLeft: "5px" }}
                                    />
                                  </Button>
                                </>
                              )}
                             
                            </>
                          ) : props.viewDetails.order_status !== "Cancelled" &&
                            props.viewDetails.order_status !== "Ignored" &&
                            (shippitDetails[shippitDetails.length - 1]
                              ?.state === "order_placed" ||
                              shippitDetails[shippitDetails.length - 1]
                                ?.state === "despatch_in_progress" ||
                              shippitDetails[shippitDetails.length - 1]
                                ?.state === "ready_for_pickup" ||
                              shippitDetails[shippitDetails.length - 1]
                                ?.state === "PlainLabelCarrier") ? (
                            <>
                              
                              {keyLoading ? (
                                <CircularProgress
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    marginLeft: "10px",
                                    position: "relative",
                                    top: "10px",
                                  }}
                                />
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleCancelDialogue()}
                                   
                                    disableElevation
                                  >
                                    Cancel this order
                                    <HighlightOff
                                      fontSize="small"
                                      style={{ marginLeft: "5px" }}
                                    />
                                  </Button>
                                </>
                              )}
                            
                            </>
                          ) : null} */}

                          {props.viewDetails.isReadyForCancelOrder === 1 && (
                            <>

                              {keyLoading ? (
                                <CircularProgress
                                  style={{
                                    height: "25px",
                                    width: "25px",
                                    marginLeft: "10px",
                                    position: "relative",
                                    top: "10px",
                                  }}
                                />
                              ) : (

                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() => handleCancelDialogue()}
                                  disableElevation
                                >
                                  Cancel this order
                                  <HighlightOff
                                    fontSize="small"
                                    style={{ marginLeft: "5px" }}
                                  />
                                </Button>

                              )}

                            </>
                          )}
                        </Values>
                      </FlexContent>


                    </Grid>

                    {/* Right Side */}

                    {/* <Grid item xs={12}>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Note</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>{props.viewDetails.note}</Values>
                      </FlexContent>
                    </Grid> */}
                  </Grid>
                </Box>
              )}
            </TabPanel>
            {/* Tab 1 ends*/}

            {/* Tab 2 starts*/}
            {/* Product Details */}
            <TabPanel value={value} index={1} dir={theme.direction}>
              {productDetails ? (
                <>
                  <Box textAlign={"right"}>
                    <Button onClick={handleBack}>
                      <KeyboardArrowLeft
                        fontSize="small"
                        sx={{ marginRight: "5px" }}
                      />
                      <span>Back</span>
                    </Button>
                  </Box>
                  <Grid container spacing={2}>
                    {/* Left Side */}
                    <Grid item xs={12} md={6}>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Name</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {productDetails.stock?.stockNameDefault || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Stock Code</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {productDetails.stock?.stockCode || "-"}
                        </Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Number of Boxes</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {productDetails.stock?.numberOfBoxes || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Status</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {productDetails.stock?.active === "Y" ? (
                            "Active"
                          ) : productDetails.reasonCode === "6" ? (
                            <span style={{ color: "red" }}>In-active</span>
                          ) : (
                            "In-active"
                          )}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Ordered Price</span> <span> : </span>
                        </FlexInnerTitle>
                        {!showOrderPriceEdit ? (
                          <Box ml={2}>
                            {productDetails.reasonCode === "3" ||
                              productDetails.reasonCode === "4" ||
                              productDetails.reasonCode === "5" ||
                              productDetails.reasonCode === "12"
                              ? (
                                <span style={{ color: "red", fontWeight: "700" }}>
                                  ${productDetails.unitPrice}
                                </span>
                              ) : (
                                <span>${productDetails.unitPrice}</span>
                              )}
                            {!(
                              props.viewDetails.order_status === "Accepted" ||
                              props.viewDetails.order_status === "Pre-Sales" ||
                              props.viewDetails.order_status === "Rejected" ||
                              props.viewDetails.order_status === "Cancelled" ||
                              props.viewDetails.order_status === "Ignored"
                            ) ? (
                              <IconButton
                                onClick={() => {
                                  setShowOrderPriceEdit(true);
                                  setOrderBranch({
                                    ...orderBranch,
                                    orderPrice: productDetails.unitPrice,
                                  });
                                }}
                              >
                                <Edit fontSize="small" color="primary" />
                              </IconButton>
                            ) : null}
                          </Box>
                        ) : (
                          <Values>
                            <TextField
                              name="orderPrice"
                              variant="outlined"
                              value={orderBranch.orderPrice}
                              onChange={(e) =>
                                setOrderBranch({
                                  ...orderBranch,
                                  orderPrice: e.target.value,
                                })
                              }
                            />
                            {orderBranchPriceLoading ? (
                              <CircularProgress
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginLeft: "10px",
                                  position: "relative",
                                  top: "10px",
                                }}
                              />
                            ) : (
                              <>
                                <Tooltip title="Back">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setShowOrderPriceEdit(false)}
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <ArrowBack fontSize="small" />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Change Order Price">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      changeOrderPrice(
                                        productDetails.orderproductID
                                      )
                                    }
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <Done fontSize="small" />
                                  </Button>
                                </Tooltip>
                              </>
                            )}
                          </Values>
                        )}
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Best Price</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {productDetails.reasonCode === "3" ||
                            productDetails.reasonCode === "4" ||
                            productDetails.reasonCode === "5" ||
                            productDetails.reasonCode === "12" ? (
                            <span style={{ color: "red", fontWeight: "700" }}>
                              ${productDetails.bestPrice}
                            </span>
                          ) : (
                            <span>${productDetails.bestPrice}</span>
                          )}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Invoice Price</span> <span> : </span>
                        </FlexInnerTitle>
                        {!showInvoicePriceEdit ? (
                          <Box ml={2}>
                            {productDetails.reasonCode === "3" ||
                              productDetails.reasonCode === "4" ||
                              productDetails.reasonCode === "5" ||
                              productDetails.reasonCode === "12" ? (
                              <span style={{ color: "red", fontWeight: "700" }}>
                                ${productDetails.invoicedPrice}
                              </span>
                            ) : (
                              <span>${productDetails.invoicedPrice}</span>
                            )}
                            {props.viewDetails.invoiceStatus === "pending" &&
                              props.viewDetails.order_status !== "Cancelled" &&
                              props.viewDetails.order_status !== "Ignored" ? (
                              <IconButton
                                disabled={productDetails.is_tw_product_process === 2}
                                onClick={() => {
                                  setShowInvoicePriceEdit(true);
                                  setOrderBranch({
                                    ...orderBranch,
                                    invoicedPrice: productDetails.invoicedPrice,
                                  });
                                }}
                              >
                                <Edit fontSize="small" color="primary" />
                              </IconButton>
                            ) : null}
                          </Box>
                        ) : (
                          <Values>
                            <TextField
                              name="invoicedPrice"
                              variant="outlined"
                              value={orderBranch.invoicedPrice}
                              onChange={(e) =>
                                setOrderBranch({
                                  ...orderBranch,
                                  invoicedPrice: e.target.value,
                                })
                              }
                            />
                            {invoiceBranchPriceLoading ? (
                              <CircularProgress
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginLeft: "10px",
                                  position: "relative",
                                  top: "10px",
                                }}
                              />
                            ) : (
                              <>
                                <Tooltip title="Back">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      setShowInvoicePriceEdit(false)
                                    }
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <ArrowBack fontSize="small" />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Change Invoice Price">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      changeInvoicePrice(
                                        productDetails.orderproductID
                                      )
                                    }
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <Done fontSize="small" />
                                  </Button>
                                </Tooltip>
                              </>
                            )}
                          </Values>
                        )}
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Average Cost</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          ${Number(productDetails.stock?.averageCost).toFixed(2)}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Ship by Date</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {productDetails.reasonCode === "7" ? (
                            <span style={{ color: "red", fontWeight: "700" }}>
                              {moment(props.viewDetails.shipByDate).format(
                                "ddd, MMM Do YYYY, h:mm a"
                              ) || "-"}
                            </span>
                          ) : props.viewDetails.shipByDate !== null ? (
                            <span>
                              {moment(props.viewDetails.shipByDate).format(
                                "ddd, MMM Do YYYY, h:mm a"
                              ) || "-"}
                            </span>
                          ) : (
                            "-"
                          )}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Order Created</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {moment(props.viewDetails.order_created).format(
                            "ddd, MMM Do YYYY, h:mm a"
                          ) || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Last Updated</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {moment(props.viewDetails.lastUpdate).format(
                            "ddd, MMM Do YYYY, h:mm a"
                          ) || "-"}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Assigned to Branch</span> <span> : </span>
                        </FlexInnerTitle>
                        {!showProductBranchChange ? (
                          <Box ml={2}>
                            {productDetails.assignToBranch?.map(
                              (branch, index) =>
                                branch.branch?.branchName +
                                "(" +
                                branch.assignedQuantity +
                                ")" +
                                (index + 1 ===
                                  productDetails.assignToBranch?.length
                                  ? ""
                                  : ", ")
                            )}
                            {props.viewDetails.isChangeOrderLineLocation === 1
                              // !(
                              //   props.viewDetails.order_status === "Accepted" ||
                              //   props.viewDetails.order_status === "Pre-Sales" ||
                              //   props.viewDetails.order_status === "Rejected" ||
                              //   props.viewDetails.order_status === "Cancelled" ||
                              //   props.viewDetails.order_status === "Ignored"
                              // ) 
                              ? (
                                <IconButton
                                  onClick={() => setShowProductBranchChange(true)}
                                >
                                  <Edit fontSize="small" color="primary" />
                                </IconButton>
                              ) : null}
                          </Box>
                        ) : (
                          <Values>
                            <FormControl
                              style={{ width: "180px", marginBottom: "10px" }}
                            >
                              <Select
                                value={
                                  branchState.lineBranchID
                                    ? branchState.lineBranchID
                                    : productDetails.assignToBranch[0]?.branch
                                      .erpBranchID
                                }
                                onChange={(e) =>
                                  handleChangeLineBranch(
                                    e,
                                    productDetails.orderproductID
                                  )
                                }
                                name="lineBranchID"
                              >
                                <MenuItem value={""}>
                                  <em>Select Branch</em>
                                </MenuItem>
                                {newStockArr.length > 0 &&
                                  newStockArr.map((stock) => (
                                    <MenuItem
                                      value={stock?.new_system_branch.erpBranchID}
                                    >
                                      {stock?.new_system_branch.branchName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>

                            {productBranchLoading ? (
                              <CircularProgress
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginLeft: "10px",
                                  position: "relative",
                                  top: "10px",
                                }}
                              />
                            ) : (
                              <>
                                <Tooltip title="Back">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      setShowProductBranchChange(false)
                                    }
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <ArrowBack fontSize="small" />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Change Branch">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={changeBranch}
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <Done fontSize="small" />
                                  </Button>
                                </Tooltip>
                              </>
                            )}
                          </Values>
                        )}
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Active Price Policy</span> <span> : </span>
                        </FlexInnerTitle>
                        {/* <Values>${productDetails.stock?.supplierCost}</Values> */}
                        <Values>
                          {productDetails.price_policy_of_stock?.length > 0
                            ? productDetails.price_policy_of_stock?.map(
                              (policy, index) => (
                                <PricePolicyBox>
                                  <div
                                    style={{
                                      position: "relative",
                                      top: "2px",
                                    }}
                                  >
                                    <small>
                                      {" "}
                                      {index + 1} {". "}
                                    </small>
                                  </div>
                                  <div>
                                    <h5>
                                      Policy Reference :{" "}
                                      {policy.policyReference +
                                        "[" +
                                        policy.erpPolicyID +
                                        "]" || "-"}{" "}
                                    </h5>
                                    <h5>
                                      Price :{" "}
                                      <span
                                        style={{
                                          color:
                                            productDetails.reasonCode ===
                                              "3" ||
                                              productDetails.reasonCode ===
                                              "4" ||
                                              productDetails.reasonCode === "5" ||
                                              productDetails.reasonCode === "12"
                                              ? "#fa7777"
                                              : "inherit",
                                        }}
                                      >
                                        {"$" + policy.price}
                                      </span>{" "}
                                    </h5>
                                    <h5>
                                      Start Date :{" "}
                                      {moment(policy.startDate).format(
                                        "ddd, MMM Do YYYY, h:mm a"
                                      ) || "-"}
                                    </h5>
                                    <h5>
                                      End Date :{" "}
                                      {moment(policy.endDate).format(
                                        "ddd, MMM Do YYYY, h:mm a"
                                      ) || "-"}
                                    </h5>
                                    <h5>
                                      Active :{" "}
                                      {policy.policyActive === "T" ? (
                                        <Check
                                          style={{ position: "absolute" }}
                                          fontSize="small"
                                          color="primary"
                                        />
                                      ) : (
                                        (
                                          <Clear
                                            style={{ position: "absolute" }}
                                            fontSize="small"
                                            color="primary"
                                          />
                                        ) || "-"
                                      )}
                                    </h5>
                                  </div>
                                </PricePolicyBox>
                              )
                            )
                            : "None"}
                        </Values>
                      </FlexContent>
                    </Grid>
                    {/* Left Side */}

                    {/* Right Side */}
                    <Grid item xs={12} md={6}>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Supply Entire PO</span> <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.company?.supply_entire_po ===
                            "1" ? (
                            <Check fontSize="small" />
                          ) : (
                            <Clear fontSize="small" />
                          )}
                        </Values>
                      </FlexContent>
                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Multi-location dispatch per PO</span>{" "}
                          <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.company?.multiLoc_desp_per_po ===
                            "1" ? (
                            <Check fontSize="small" />
                          ) : (
                            <Clear fontSize="small" />
                          )}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Multi-location dispatch for order line</span>{" "}
                          <span> : </span>
                        </FlexInnerTitle>
                        <Values>
                          {props.viewDetails.company?.multiLoc_desp_per_line ===
                            "1" ? (
                            <Check fontSize="small" />
                          ) : (
                            <Clear fontSize="small" />
                          )}
                        </Values>
                      </FlexContent>

                      <FlexContent>
                        <FlexInnerTitle>
                          <span>Ordered Quantity</span> <span> : </span>
                        </FlexInnerTitle>
                        {!showOrderQuantityEdit ? (
                          <Box ml={2}>
                            {productDetails.reasonCode === "1" ||
                              productDetails.reasonCode === "2" ? (
                              <span style={{ color: "#fa7777" }}>
                                {productDetails.quantity || "-"}
                              </span>
                            ) : (
                              <span>{productDetails.quantity || "-"}</span>
                            )}
                            {!(
                              props.viewDetails.order_status === "Accepted" ||
                              props.viewDetails.order_status === "Pre-Sales" ||
                              props.viewDetails.order_status === "Rejected" ||
                              props.viewDetails.order_status === "Cancelled" ||
                              props.viewDetails.order_status === "Received" ||
                              props.viewDetails.order_status === "Ignored"
                            ) ? (
                              <IconButton
                                onClick={() => {
                                  setShowOrderQuantityEdit(true);
                                  setOrderBranch({
                                    ...orderBranch,
                                    orderQuantity: productDetails.quantity,
                                  });
                                }}
                              >
                                <Edit fontSize="small" color="primary" />
                              </IconButton>
                            ) : null}
                          </Box>
                        ) : (
                          <Values>
                            <TextField
                              name="orderQuantity"
                              variant="outlined"
                              value={orderBranch.orderQuantity}
                              onChange={(e) =>
                                setOrderBranch({
                                  ...orderBranch,
                                  orderQuantity: e.target.value,
                                })
                              }
                            />
                            {orderBranchQuantityLoading ? (
                              <CircularProgress
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginLeft: "10px",
                                  position: "relative",
                                  top: "10px",
                                }}
                              />
                            ) : (
                              <>
                                <Tooltip title="Back">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      setShowOrderQuantityEdit(false)
                                    }
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <ArrowBack fontSize="small" />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Change Order Quantity">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      changeOrderQuantity(
                                        productDetails.orderproductID
                                      )
                                    }
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    <Done fontSize="small" />
                                  </Button>
                                </Tooltip>
                              </>
                            )}
                          </Values>
                        )}
                      </FlexContent>

                      <Box mb={5}>
                        <h4
                          style={{
                            marginTop: "0",
                            marginBottom: "10px",
                            paddingBottom: "5px",
                            borderBottom: "2px solid #ccc",
                          }}
                        >
                          Stock on Hand [Sort by Priority List]
                        </h4>
                        {/* {newStockArr.length > 0
                            ? newStockArr.map((stock, index) => (
                              <PricePolicyBox>
                                <div
                                  style={{ position: "relative", top: "2px" }}
                                >
                                  <small>
                                    {" "}
                                    {index + 1} {". "}
                                  </small>
                                </div>
                                <div>
                                  <h5>
                                    {stock?.new_system_branch.branchName} :{" "}
                                    <span
                                      style={{
                                        color:
                                          productDetails.reasonCode === "1" ||
                                            productDetails.reasonCode === "2"
                                            ? "#fa7777"
                                            : "inherit",
                                      }}
                                    >
                                      {" "}
                                      <span>{Math.max(stock?.actualSOH, 0)}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span style={{ fontSize:"12px", fontWeight:"400" }}>{" ("}Commited Stock - {Math.max(stock?.comitedStock, 0)}{") "}</span>
                                      <span style={{ fontSize:"12px", fontWeight:"400" }}>{" ("}Total Stock - {Math.max(stock?.totalInStock, 0)}{") "}</span>
                                    </span>
                                  </h5>
                                </div>
                              </PricePolicyBox>
                            ))
                            : ""} */}

                        {newStockArr.length > 0 ? (
                          <BasicTable3
                            columns={StockOnHandColumn}
                            rows={newStockArr}
                          />
                        ) : (
                          <h4>
                            <span
                              style={{
                                marginLeft: "0px",
                                color: "#fa7777",
                              }}
                            >
                              {
                                "The product has not been assigned to any branch."
                              }
                            </span>
                          </h4>
                        )}
                      </Box>
                      <FlexContent>
                        <h4>
                          <span>Note</span> <span> : </span>
                        </h4>
                        <h4>
                          <span
                            style={{
                              marginLeft: "10px",
                              color:
                                productDetails.reportType === "exceptionalOrder"
                                  ? "#fa7777"
                                  : "inherit",
                            }}
                          >
                            {productDetails.reason}
                          </span>
                        </h4>
                      </FlexContent>

                      {/* <FlexContent>
                          <FlexInnerTitle></FlexInnerTitle>
                          <Values style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
  
                            {!(
  
                              props.viewDetails.order_status === "Rejected" ||
                              props.viewDetails.order_status === "Cancelled"
                            )
                              ? (
                                <>
  
                                  {keyLoading ? (
                                    <CircularProgress
                                      style={{
                                        height: "25px",
                                        width: "25px",
                                        marginLeft: "10px",
                                        position: "relative",
                                        top: "10px",
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleCancelProductDialogue()}
                                        disabled={productDetails.status === "cancelled"}
  
                                        disableElevation
  
                                      >
                                        Reject this order line
                                        <HighlightOff
                                          fontSize="small"
                                          style={{ marginLeft: "5px" }}
                                        />
                                      </Button>
  
                                    </>
                                  )}
  
                                </>
                              ) : null}
                          </Values>
                        </FlexContent> */}
                    </Grid>

                    {/* Right Side */}
                  </Grid>
                </>
              ) : (
                <BasicTableViewNetsuite
                  columns={
                    props.viewDetails.order_status === "Accepted" ||
                      props.viewDetails.order_status === "Pre-Sales" ||
                      props.viewDetails.order_status === "Rejected" ||
                      props.viewDetails.order_status === "Cancelled" ||
                      props.viewDetails.order_status === "Ignored" ||
                      props.viewDetails.reportType !== "exceptionalOrder"
                      ? productColumns
                      : props.viewDetails.orderType === "TW" ? productExceptionalColumnsTW : productExceptionalColumns
                  }
                  rows={props.viewDetails.orderProduct}
                  shippitDetails={shippitDetails}
                  showActive={showActive}
                  showPricePolicy={showPricePolicy}
                  handleView={handleView}
                  orderStatus={props.viewDetails.order_status}
                  isShowManuallyProcessButton={props.viewDetails.isShowManuallyProcessButton}
                  invoiceStatus={props.viewDetails.invoiceStatus}
                  reportType={props.viewDetails.reportType}
                  id={props.viewDetails.newSystemOrderID}
                  orderID={props.viewDetails.orderID}
                  netsuiteOrderNumber={props.viewDetails.netsuiteOrderNumber}
                  isReprocessOrder={props.viewDetails.isReprocessOrder}
                  isChangeOrderLineLocation={props.viewDetails.isChangeOrderLineLocation}
                  viewDetails={props.viewDetails}
                  handleRefetch={handleRefetch}
                  setValue={setValue}
                />
              )}
            </TabPanel>
            {/* Tab 2 ends*/}
            {/* Product Details */}

            {/* Tab 3 starts*/}
            {/* Shippit Details */}

            <TabPanel value={value} index={2} dir={theme.direction}>
              {/* <Grid item xs={12} md={6}>
                  <FlexContent>
                    <FlexInnerTitle>
                      <span>Location</span> <span> : </span>
                    </FlexInnerTitle>
                    <Values>
                      <LocationSearchInput/>
                      </Values>
                  </FlexContent>
                </Grid> */}{" "}
              {(shippitDetails.length &&
                ((props.viewDetails.orderType === "TW" || props.viewDetails.order_status === "Accepted") ||
                  (props.viewDetails.orderType === "TW" || props.viewDetails.order_status === "Pre-Sales"))) ||
                (props.viewDetails.isSkuLogistics !== 1 &&
                  props.viewDetails.order_status === "Cancelled") ? (
                <BasicTableView1
                  columns={
                    props.viewDetails.orderType === "TW" ?
                      shippitColumns1
                      // :
                      // props.viewDetails.isSkuLogistics === 1 ?
                      //   shippitColumns1
                      : shippitColumns
                  }
                  rows={shippitDetails}
                  showActive={showActive}
                  viewDetails={shippitDetails}
                  shipment={props.viewDetails.shipment}
                  handleRefetch={handleRefetch}
                  id={props.viewDetails.newSystemOrderID}
                  order_status={props.viewDetails.order_status}
                  orderType={props.viewDetails.orderType}
                  isSkuLogistics={props.viewDetails.isSkuLogistics}
                  isShippingLabelGenerate={props.viewDetails.isShippingLabelGenerate}
                  isReadyForCancelOrder={props.viewDetails.isReadyForCancelOrder}
                  manifestSynccareMissingButton={props.viewDetails.manifestSynccareMissingButton}
                  isBookToShippitOrder={props.viewDetails.isBookToShippitOrder}
                  refresh={refresh}
                  loading={loading}
                />
              ) : (
                <Box p={3}>
                  {!(
                    props.viewDetails.order_status === "Accepted" ||
                    props.viewDetails.order_status === "Pre-Sales" ||
                    props.viewDetails.order_status === "Rejected" ||
                    props.viewDetails.order_status === "Cancelled"
                  ) ? (
                    <span>
                      Please Accept or Reject the order to show the shipping
                      details.
                    </span>
                  ) : props.viewDetails.order_status === "Rejected" ? (
                    <span>
                      This order has been REJECTED to show the shipping details.
                    </span>
                  ) : props.viewDetails.isSkuLogistics === 1 &&
                    props.viewDetails.order_status === "Cancelled" ? (
                    <span>
                      This order has been CANCELLED to show the shipping
                      details.
                    </span>
                  ) : (
                    <span>
                      Please wait. Your shipping records are being processed.
                    </span>
                  )}
                </Box>
              )}
            </TabPanel>

            {/* Tab 3 ends*/}
            {/* Shippit Details */}
            {/* Tab 3 starts*/}
            {/* Label Pdf */}

            <TabPanel value={value} index={3} dir={theme.direction}>
              {preSellDetails ? (
                <>
                  <Box textAlign={"right"}>
                    <Button onClick={handlePreSellBack}>
                      <KeyboardArrowLeft
                        fontSize="small"
                        sx={{ marginRight: "5px" }}
                      />
                      <span>Back</span>
                    </Button>
                  </Box>

                  <BasicTableView1
                    columns={preSellContainerColumns}
                    rows={preSellDetails.containerDetails}
                  />
                </>
              ) : (
                <PreSellTableComponent
                  columns={preSellColumns}
                  rows={props.viewDetails?.preSell.filter((p) => p.containerPoNumber !== null)}
                  showPreSell={showPreSell}
                />
              )}
            </TabPanel>

            {/* Tab 3 ends*/}
            {/* Shippit Details */}
          </DialogContent>
        )}

        {/* <DialogActions>
          <span style={{left : '0%'}}><i>All the prices are at ex GST</i></span>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Close
            </Button>
          </DialogActions> */}
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span style={{ fontSize: "14px", marginLeft: "10px" }}>
            <i>* All the prices are at ex GST</i>
          </span>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {openCancelDialog && (
        <CancelDialog
          sendToCancel={sendToCancel}
          viewDetails={props.viewDetails}
        />
      )}
      {/* {openProcessDialog && (
          <ProcessDialog
            sendToProcess={sendToProcess}
            viewDetails={props.viewDetails}
          />
        )} */}
      {/* {openCancelProductDialog && (
          <CancelProductDialog
            sendToCancelProduct={sendToCancelProduct}
            viewDetails={props.viewDetails}
          />
        )} */}

      {openStatusDialog && (
        <StatusDialog
          sendChangeOrder={sendChangeOrder}
          status={acceptStatus}
          viewDetails={props.viewDetails}
          selectedChecked={[]}
        />
      )}

      {showAssignBranchDialog && (
        <AssignedDialog sendAssignBranch={sendAssignBranch} />
      )}

      {openSoubaDialog && (
        <RefetchSoubaDialog sendToSouba={sendToSouba} />
      )}

      {showEditDialog && (
        <EditDialog sendReset={sendReset} viewDetails={[props.viewDetails]} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ViewOrderDialog;
