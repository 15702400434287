import * as React from "react";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  ChevronLeft,
  ChevronRight,
  Dashboard,
  Dns,
  People,
  Inventory,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Groups,
  ViewStream,
  Settings,
  GppMaybe,
  Adjust,
  LocalShipping,
  Sync,
  Navigation,
  RunningWithErrors,
} from "@mui/icons-material";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  ClickAwayListener,
  Collapse,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SwipeableDrawer,
  Snackbar,
  Button,
  CircularProgress,
  Tooltip,
  Badge,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import Global from "../../utils/GlobalValues";
import { httpclient } from "../../utils/httpClient";
import ResetDialog from "../../Private/Online/Members/ResetDialog";
import PATH from "../../Routing/PATHS";
import onlineSubMenus from "./SubMenus/onlineSubMenus";
import replenishmentSubMenus from "./SubMenus/replenishmentSubMenu";
import netsuiteSubMenus from "./SubMenus/netsuiteSubMenus";
import featureServer from "../../utils/featureServer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const drawerWidth = 320;

const openedMixin = (theme) => ({
  width: drawerWidth,

  backgroundColor: theme.palette.primary.dark,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",

  width: "0px",

  backgroundColor: theme.palette.primary.dark,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  marginLeft: "20px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  "& img": {
    width: "230px",
    height: "45px",
    marginTop: "7px",
  },
  "& a": {
    textDecoration: "none",
    color: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
  },
  "& h3": {
    letterSpacing: "1px",
    fontSize: "25px",
    margin: "0",
  },
  "& small": {
    fontSize: "13px",
    position: "relative",
    top: "10px",
    left: "-3px",
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: theme.palette.primary.main,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      zIndex: theme.zIndex.drawer - 1,
    },
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  backgroundColor: theme.palette.primary.dark,

  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Swipeable = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const MultiLevelList = styled(List)(({ theme }) => ({
  marginLeft: "30px",

  borderLeft: `2px solid ${theme.palette.primary.dark}`,
}));

const MenuListButton = styled(ListItemButton)(({ theme }) => ({
  opacity: "0.7",
  transition: "0.3s",
  fontWeight: "400",
  margin: "5px 8px",
  borderRadius: "10px",

  color: theme.palette.primary.light,
  "& svg": {
    color: theme.palette.primary.light,
    fontSize: "22px",
  },
  "& span": {
    fontWeight: "600",
    fontSize: "15px",
  },
  "&:hover": {
    opacity: "1",
  },
  "&:active": {
    opacity: "1",
  },
  "&:focus": {
    opacity: "1",
  },
}));

const AvatarDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "& span": {
    color: theme.palette.primary.light,
    fontSize: "18px",
    marginRight: "10px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const StagingDiv = styled("div")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "700",
  letterSpacing: "1px",
  "& span": {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "600",
  }
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -10,
    top: 0,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const location = useLocation();
  const anchorRef = React.useRef(null);
  const [time, setTime] = useState(1800);
  const [count, setCount] = useState(0);
  const [openResetDialog, setOpenResetDialog] = React.useState(false);
  const [viewDetails, setViewDetails] = React.useState({});
  const [openSnack, setOpenSnack] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [messageState, setMessageState] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [resume, setResume] = React.useState(0);
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [currMenuName, setCurrMenuName] = React.useState("");
  const [currMenuName1, setCurrMenuName1] = React.useState("");
  const [currMenuName2, setCurrMenuName2] = React.useState("");
  const [menuLevelOpen, setMenuLevelOpen] = React.useState(false);
  const [menuLevelOpen1, setMenuLevelOpen1] = React.useState(false);
  const [menuLevelOpen2, setMenuLevelOpen2] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const navigate = useNavigate();
  var loginData = localStorage.getItem("login");
  var loginValue = JSON.parse(loginData);
  const [moduleList, setModuleList] = useState([]);
  const [seconds, setSeconds] = useState(60);
  const timerRef = React.useRef(null);

  useEffect(() => {
    getAllModules();
  }, []);


  useEffect(() => {
    // Timer logic
    timerRef.current = setInterval(() => {
      setSeconds((prev) => (prev > 0 ? prev - 1 : 60));
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, []);

  const getAllModules = () => {
    httpclient
      .get("modules")
      .then(({ data }) => {
        if (data.success) {
          const filteredModules = data.data.filter(
            (module) => module.is_active === 1
          );
          setModuleList(filteredModules);
        } else if (data.status === 401) {
          localStorage.clear();
          window.location = "/#/login";
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      });
  };


  const drawerList = [
    { menu: "Dashboard", link: PATH.DASHBOARD, icon: <Dashboard /> },
    {
      menu: "Settings",
      link: null,
      icon: <Settings />,
      higherLevel: true,
      multiLevel: true,
      multiMenus: [
        { menu: "Sync", link: PATH.SETTINGS_SYNC },
        { menu: "3PL", link: PATH.SETTINGS_3PL },
        { menu: "Holiday Calender", link: PATH.SETTINGS_HOLIDAY },
        { menu: "NetSuite Price Settings", link: PATH.NETSUITE_PRICE_SETTINGS },
      ],
    },
    ...(loginValue.UserLevel === 2
      ? moduleList
        .filter((module) =>
          loginValue?.userModule.some(
            (userModule) => userModule === module.name
          )
        )
        .map((module) => ({
          menu: module.name,
          // link: module.name === "Online" ? "" : "/modules_view",
          link: null,
          icon: null,
          // higherLevel: module.name === "Online" ? true : false,
          higherLevel: true,
          multiMenus:
            module.name === "Online"
              ? [...onlineSubMenus]
              : module.name === "Replenishment"
                ? [...replenishmentSubMenus]
                : module.name === "NetSuite"
                  ? [...netsuiteSubMenus]
                  : [],

        }))
      : moduleList.map((module) => ({
        menu: module.name,
        // link: module.name === "Online" ? "" : "/modules_view",
        link: null,
        icon: null,
        higherLevel: true,
        // higherLevel: module.name === "Online" ? true : false,
        multiMenus:
          module.name === "Online"
            ? [...onlineSubMenus]
            : module.name === "Replenishment"
              ? [...replenishmentSubMenus]
              : module.name === "NetSuite"
                ? [...netsuiteSubMenus]
                : [],
      }))),
  ];

  React.useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  });

  const resize = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleDrawerOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleDrawerMobileOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerMobileClose = () => {
    setMobileOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setOpenPopper(false);

    navigate(PATH.LOGIN);
  };

  const handleMenuDown = (name) => {
    setCurrMenuName(name);
    if (name !== currMenuName) {
      setMenuLevelOpen(true);
    } else if (name === currMenuName) {
      setMenuLevelOpen((prev) => !prev);
    } else {
      setMenuLevelOpen(false);
    }
  };
  const handleMenuDown1 = (name) => {
    setCurrMenuName1(name);
    if (name !== currMenuName1) {
      setMenuLevelOpen1(true);
    } else if (name === currMenuName1) {
      setMenuLevelOpen1((prev) => !prev);
    } else {
      setMenuLevelOpen1(false);
    }
  };

  const handleMenuDown2 = (name) => {
    setCurrMenuName2(name);
    if (name !== currMenuName2) {
      setMenuLevelOpen2(true);
    } else if (name === currMenuName2) {
      setMenuLevelOpen2((prev) => !prev);
    } else {
      setMenuLevelOpen2(false);
    }
  };

  const handleEditProfile = () => {
    navigate(`${PATH.ONLINE_USERS_ADD}/${loginValue.userID}`, {
      state: "edit_profile",
    });

    setOpenPopper(false);
  };

  const handlePopperOpen = () => {
    setOpenPopper((prev) => !prev);
  };

  const handleClosePopper = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenPopper(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenPopper(false);
    } else if (event.key === "Escape") {
      setOpenPopper(false);
    }
  };

  const MenuDiv = styled("div")(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: mobileOpen ? "none" : "block",
    },
  }));

  const DesktopMenuDiv = styled("div")(({ theme }) => ({
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const handleResetPassword = (row) => {
    setOpenResetDialog(true);
    setViewDetails(row);
  };

  const sendReset = (call) => {
    if (call.open === false) {
      setOpenResetDialog(false);
      setViewDetails({});
    }
    if (call.success === true) {
      httpclient
        .post(`user/reset-password/${viewDetails.userID}`, {
          password: call.password,
          password_confirmation: call.confirmPassword,
        })
        .then(({ data }) => {
          if (data.success) {
            setOpenSnack(true);
            setMessageState("success");
            setMessage(data.message);
            setViewDetails({});
          } else {
            setOpenSnack(true);
            setMessageState("error");
            setMessage(data.message);
          }
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleResume = () => {
    setLoading(true);
    httpclient
      .post(
        `temple-webster/all-marketplace-order-import-resume`
      )
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setOpenSnack(true);
          setMessageState("success");
          setMessage(data.message);
          setTimeout(() => window.location.reload()
            , 1000)

        } else {
          setLoading(false);
          setOpenSnack(true);
          setMessageState("error");
          setMessage(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setOpenSnack(true);
        setMessageState("error");
        setMessage(err.message);
      });
  };

  console.log("Module List", moduleList);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);


  useEffect(() => {
    if (time === 0 || time === 1800) {
      httpclient.get(`temple-webster/marketplace-order-assign-process-status`).then(({ data }) => {
        if (data.success) {
          setResume(data.data?.resumeButton)
          const [minutesStr, secondsStr] = data.data?.resumingTime?.split(':');
          const minutes = parseInt(minutesStr);
          const seconds = parseInt(secondsStr);
          const totalSeconds = (minutes * 60) + seconds;
          setTime(totalSeconds);
        }
      })
    };

  }, [time]);

  useEffect(() => {
    if (seconds === 60) {
      fetchData();
    }
    // const intervalId = setInterval(fetchData, 30 * 1000);
    // return () => clearInterval(intervalId);
  }, [seconds]);


  const fetchData = () => {

    httpclient
      .get(`workflow/alert-issue`)
      .then(({ data }) => {
        if (data) {
          setCount(data.count);
          setAlertMessage(data.message);
        } else {
          console.log("Error!");
        }
      }).catch(err => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        }
      })
  };

  const handleClick = () => {
    navigate('/netsuite/workflow/marketplace', { state: { count } });
  };

  console.log("dlist", drawerList)

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* NAVBAR */}
      <AppBar position="fixed" open={screenWidth > 768 ? open : mobileOpen}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <DesktopMenuDiv>
            <IconButton onClick={handleDrawerOpen}>
              {open ? (
                <ChevronLeft fontSize="large" style={{ color: "#fff" }} />
              ) : (
                <MenuIcon fontSize="large" style={{ color: "#fff" }} />
              )}
            </IconButton>
          </DesktopMenuDiv>

          <MenuDiv>
            <IconButton onClick={handleDrawerMobileOpen}>
              <MenuIcon fontSize="large" style={{ color: "#fff" }} />
            </IconButton>
          </MenuDiv>


          <StagingDiv>
            {featureServer && ("Staging")}
            {/* {(count !== "0") && ( */}
              <>
                <Tooltip title={alertMessage}>
                  <IconButton onClick={handleClick}>
                    <StyledBadge badgeContent={count === 0 ? "0" : count} color="warning" max={99}>
                      <RunningWithErrors color="warning" />
                    </StyledBadge>
                  </IconButton>
                </Tooltip>

                <span style={{ marginLeft: "12px" }}>Refreshing On: <strong>00:{String(seconds).padStart(2, '0')}</strong></span>

              </>
             {/* )}  */}
          </StagingDiv>



          {resume === 1 &&
            <>
              <span style={{ fontWeight: "600" }}>Order Assign Process Currently Paused &nbsp;
                {loading ? (
                  <CircularProgress
                    style={{
                      height: "25px",
                      width: "25px",
                      marginLeft: "20px",
                      color: "green",
                      position: "relative",
                      //top: "10px",
                    }}
                  />
                ) : (
                  <Button
                    onClick={handleResume}
                    color="success"
                    variant="contained">
                    Resume Now
                  </Button>
                )
                }
              </span>
              <span style={{ fontWeight: "600" }}>Order Process Resuming in {`${Math.floor(time / 60)}`.padStart(2, 0)}:
                {`${time % 60}`.padStart(2, 0)}
              </span>
            </>
          }

          {/* <SearchDiv>
            <input type="text" placeholder="Search" />
            <button><Search fontSize="small" /></button>
          </SearchDiv> */}
          <AvatarDiv>
            <span>
              {loginValue.firstName} (
              {loginValue.UserLevel === 1 ? "Admin" : "Staff"})
            </span>
            <Avatar
              alt={loginValue.firstName}
              sx={{
                width: 43,
                height: 43,
                cursor: "pointer",
                border: `2px solid #fafafa`,
              }}
              onClick={handlePopperOpen}
              ref={anchorRef}
            />

            <Popper
              open={openPopper}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClosePopper}>
                      <MenuList
                        autoFocusItem={openPopper}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={handleEditProfile}>
                          Edit Profile
                        </MenuItem>
                        {loginValue && loginValue.UserLevel === 2 && (
                          <MenuItem
                            onClick={() => handleResetPassword(loginValue)}
                          >
                            Reset Password
                          </MenuItem>
                        )}
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </AvatarDiv>
        </Toolbar>
      </AppBar>
      {/* NAVBAR */}

      {/* SIDEBAR OR DRAWER */}
      {screenWidth > 768 ? (
        <Drawer open={open} variant="permanent">
          <DrawerHeader>
            <Link to="/">
              {/* <h3>Sync Care</h3> */}
              <img src="/synccare-1.png" alt="synccare_logo" />
              <small>{Global.version}</small>
            </Link>
          </DrawerHeader>
          <Divider />
          <List>
            {drawerList.map((list, index) => (

              <>
                <ListItem
                  key={list.menu}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <Link to={list.link} style={{ textDecoration: "none" }}>
                    <MenuListButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        background:
                          location.pathname === list.link
                            ? theme.palette.secondary.main
                            : "transparent",
                        opacity: location.pathname === list.link ? "1" : "0.7",
                      }}
                      onClick={() =>
                        handleMenuDown(list.higherLevel ? list.menu : "")
                      }
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 1.6 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {list.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={list.menu}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                      {list.higherLevel ? (
                        currMenuName === list.menu ? (
                          <KeyboardArrowDown />
                        ) : (
                          <KeyboardArrowUp />
                        )
                      ) : (
                        ""
                      )}
                    </MenuListButton>
                  </Link>
                </ListItem>

                {list.higherLevel && currMenuName === list.menu && (
                  <Collapse in={menuLevelOpen} timeout="auto" unmountOnExit>
                    <>
                      <Divider />
                      <MultiLevelList>
                        {list.higherLevel &&
                          list.multiMenus.map((sub, index) => (
                            <>
                              {((sub.menu === "Users" ||
                                sub.menu === "Settings") &&
                                loginValue.UserLevel === 2) ||
                                ((
                                  // sub.menu === "Shippit Testing" || sub.menu === "Quick Ref" || sub.menu === "Sales Override" || sub.menu === "Stock Substitute") &&
                                  sub.menu === "Shippit Testing") &&
                                  featureServer) ? null : (
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <Link
                                    to={sub.link}
                                    state={sub.state}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <MenuListButton
                                      sx={{
                                        minHeight: 48,
                                        justifyContent: open
                                          ? "initial"
                                          : "center",
                                        px: 2.5,
                                        background:
                                          location.pathname === sub.link
                                            ? theme.palette.secondary.main
                                            : "transparent",
                                        opacity:
                                          location.pathname === sub.link
                                            ? "1"
                                            : "0.7",
                                      }}
                                      onClick={() =>
                                        handleMenuDown1(
                                          sub.multiLevel ? sub.menu : ""
                                        )
                                      }
                                    >
                                      <ListItemIcon
                                        sx={{
                                          minWidth: 0,
                                          mr: open ? 1.6 : "auto",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {sub.icon}
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={sub.menu}
                                        sx={{ opacity: open ? 1 : 0 }}
                                      />
                                      {sub.multiLevel ? (
                                        currMenuName === sub.menu ? (
                                          <KeyboardArrowDown />
                                        ) : (
                                          <KeyboardArrowUp />
                                        )
                                      ) : (
                                        <ChevronRight />
                                      )}
                                      {/* <ListItemText
                                      primary={sub.menu}
                                      sx={{ opacity: open ? 1 : 0 }}
                                    />
                                    <ChevronRight /> */}
                                    </MenuListButton>
                                  </Link>
                                  {/* )} */}
                                </ListItem>
                              )}
                              {sub.multiLevel && currMenuName1 === sub.menu && (
                                <Collapse
                                  in={menuLevelOpen1}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <>
                                    <Divider />
                                    <MultiLevelList>
                                      {sub.multiLevel &&
                                        sub.multiMenus.map((sub1, index) => {
                                          console.log("sub1", sub1);
                                          if (sub1.menuName === "Marketplaces" && !featureServer) {
                                            return null
                                          }
                                          else if ((sub1.menuName === "Quick Ref" || sub1.menuName === "Sales Override" || sub1.menuName === "Stock Substitute") &&
                                            featureServer) {
                                            return null
                                          }
                                          else {
                                            return (
                                              <ListItem
                                                disablePadding
                                                sx={{ display: "block" }}
                                              >
                                                <Link
                                                  to={sub1.link}
                                                  state={sub1.state}
                                                  style={{ textDecoration: "none" }}
                                                >
                                                  <MenuListButton
                                                    sx={{
                                                      minHeight: 48,
                                                      justifyContent: open
                                                        ? "initial"
                                                        : "center",
                                                      px: 2.5,
                                                      background:
                                                        location.pathname ===
                                                          sub1.link
                                                          ? theme.palette.secondary
                                                            .main
                                                          : "transparent",
                                                      opacity:
                                                        location.pathname ===
                                                          sub1.link
                                                          ? "1"
                                                          : "0.7",
                                                    }}

                                                    onClick={() =>
                                                      handleMenuDown2(
                                                        sub1.multiLevel ? sub1.menuName : ""
                                                      )
                                                    }
                                                  >
                                                    {/* <ListItemIcon
                                                      sx={{
                                                        minWidth: 0,
                                                        mr: open ? 1.6 : "auto",
                                                        justifyContent: "center",
                                                      }}
                                                    >
                                                      {sub1.icon}
                                                    </ListItemIcon> */}
                                                    <ListItemText
                                                      primary={sub1.menuName}
                                                      sx={{ opacity: open ? 1 : 0 }}
                                                    />
                                                    {sub1.multiLevel ? (
                                                      currMenuName2 === sub1.menuName ? (
                                                        <KeyboardArrowDown />
                                                      ) : (
                                                        <KeyboardArrowUp />
                                                      )
                                                    ) : (
                                                      <ChevronRight />
                                                    )}
                                                  </MenuListButton>
                                                </Link>
                                                {/* )} */}


                                                {sub1.multiLevel && currMenuName2 === sub1.menuName && (
                                                  <Collapse
                                                    in={menuLevelOpen2}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    {/* Handle `sub1`'s submenus */}
                                                    <MultiLevelList>
                                                      {sub1.multiMenus.map((sub2, index) => {
                                                        console.log("sub2", sub2);
                                                        if (sub2.menuName === "Orders" && !featureServer) {
                                                          return null
                                                        }
                                                        else {
                                                          return (
                                                            <ListItem
                                                              disablePadding
                                                              sx={{ display: "block" }}
                                                            >
                                                              <Link
                                                                to={sub2.link}
                                                                state={sub2.state}
                                                                style={{ textDecoration: "none" }}
                                                              >
                                                                <MenuListButton
                                                                  sx={{
                                                                    minHeight: 48,
                                                                    justifyContent: open
                                                                      ? "initial"
                                                                      : "center",
                                                                    px: 2.5,
                                                                    background:
                                                                      location.pathname === sub2.link
                                                                        ? theme.palette.secondary.main
                                                                        : "transparent",
                                                                    opacity:
                                                                      location.pathname === sub2.link
                                                                        ? "1"
                                                                        : "0.7",
                                                                  }}

                                                                >
                                                                  {/* <ListItemIcon
                                                                    sx={{
                                                                      minWidth: 0,
                                                                      mr: open ? 1.6 : "auto",
                                                                      justifyContent: "center",
                                                                    }}
                                                                  >
                                                                    {sub2.icon}
                                                                  </ListItemIcon> */}
                                                                  <ListItemText
                                                                    primary={sub2.menuName}
                                                                    sx={{ opacity: open ? 1 : 0 }}
                                                                  />
                                                                  {/* <ChevronRight /> */}
                                                                  {/* <ListItemText
                                                         primary={sub.menu}
                                                         sx={{ opacity: open ? 1 : 0 }}
                                                       />
                                                       <ChevronRight /> */}
                                                                </MenuListButton>
                                                              </Link>
                                                              {/* )} */}
                                                            </ListItem>
                                                          )
                                                        }
                                                      })}
                                                    </MultiLevelList>
                                                  </Collapse>
                                                )}
                                              </ListItem>
                                            )
                                          }
                                        })}
                                    </MultiLevelList>
                                  </>
                                </Collapse>
                              )}
                            </>
                          ))}
                      </MultiLevelList>
                    </>
                  </Collapse>
                )}

              </>
            ))}
          </List>
        </Drawer>
      ) : (
        <Swipeable
          anchor="left"
          open={mobileOpen}
          onOpen={handleDrawerMobileOpen}
          onClose={handleDrawerMobileClose}
        >
          <DrawerHeader>
            <Link to="/">
              {/* <h3>Sync Care</h3> */}
              <img src="/synccare-1.png" alt="synccare_logo" />

              <small>{Global.version}</small>
            </Link>
            {/* <IconButton onClick={handleDrawerMobileClose}>
            <ChevronLeftIcon fontSize="large" color="secondary" />
          </IconButton> */}
          </DrawerHeader>
          <Divider />
          <List>
            {drawerList.map((list, index) => {
              return (
                <>
                  <ListItem
                    key={list.menu}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <Link to={list.link} style={{ textDecoration: "none" }}>
                      <MenuListButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                          background:
                            location.pathname === list.link
                              ? theme.palette.secondary.main
                              : "transparent",
                          opacity:
                            location.pathname === list.link ? "1" : "0.7",
                        }}
                        onClick={() =>
                          handleMenuDown(list.higherLevel ? list.menu : "")
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {list.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={list.menu}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                        {list.higherLevel ? (
                          currMenuName === list.menu ? (
                            <KeyboardArrowDown />
                          ) : (
                            <KeyboardArrowUp />
                          )
                        ) : (
                          ""
                        )}
                      </MenuListButton>
                    </Link>
                  </ListItem>
                  {list.higherLevel && currMenuName === list.menu && (
                    <Collapse in={menuLevelOpen} timeout="auto" unmountOnExit>
                      <>
                        <Divider />
                        <MultiLevelList>
                          {list.higherLevel &&
                            list.multiMenus.map((sub, index) => (
                              <>
                                {((sub.menu === "Users" ||
                                  sub.menu === "Settings") &&
                                  loginValue.UserLevel === 2) ||
                                  ((
                                    sub.menu === "Shippit Testing") &&
                                    !featureServer) ? null : (
                                  <ListItem
                                    disablePadding
                                    sx={{ display: "block" }}
                                  >
                                    <Link
                                      to={sub.link}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <MenuListButton
                                        sx={{
                                          minHeight: 48,
                                          justifyContent: open
                                            ? "initial"
                                            : "center",
                                          px: 2.5,
                                          background:
                                            location.pathname === list.link
                                              ? theme.palette.secondary.main
                                              : "transparent",
                                          opacity:
                                            location.pathname === sub.link
                                              ? "1"
                                              : "0.7",
                                        }}
                                        onClick={() =>
                                          handleMenuDown1(
                                            sub.multiLevel ? sub.menu : ""
                                          )
                                        }
                                      >
                                        <ListItemIcon
                                          sx={{
                                            minWidth: 0,
                                            mr: open ? 1.6 : "auto",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {sub.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={sub.menu}
                                          sx={{ opacity: open ? 1 : 0 }}
                                        />
                                        {sub.multiLevel ? (
                                          currMenuName === sub.menu ? (
                                            <KeyboardArrowDown />
                                          ) : (
                                            <KeyboardArrowUp />
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </MenuListButton>
                                    </Link>
                                  </ListItem>
                                )}
                                {sub.multiLevel &&
                                  currMenuName1 === sub.menu && (
                                    <Collapse
                                      in={menuLevelOpen1}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <>
                                        <Divider />
                                        <MultiLevelList>
                                          {sub.multiLevel &&
                                            sub.multiMenus.map(
                                              (sub1, index) => {
                                                if (sub1.menuName === "Marketplaces" && !featureServer) {
                                                  return null
                                                }
                                                else if ((sub1.menuName === "Quick Ref" || sub1.menuName === "Sales Override" || sub1.menuName === "Stock Substitute") &&
                                                  featureServer) {
                                                  return null
                                                }
                                                else {
                                                  return (
                                                    <ListItem
                                                      disablePadding
                                                      sx={{ display: "block" }}
                                                    >
                                                      <Link
                                                        to={sub1.link}
                                                        state={sub1.state}
                                                        style={{
                                                          textDecoration: "none",
                                                        }}
                                                      >
                                                        <MenuListButton
                                                          sx={{
                                                            minHeight: 48,
                                                            justifyContent: open
                                                              ? "initial"
                                                              : "center",
                                                            px: 2.5,
                                                            background:
                                                              location.pathname ===
                                                                sub1.link
                                                                ? theme.palette
                                                                  .secondary.main
                                                                : "transparent",
                                                            opacity:
                                                              location.pathname ===
                                                                sub1.link
                                                                ? "1"
                                                                : "0.7",
                                                          }}
                                                        >
                                                          <ListItemText
                                                            primary={sub1.menuName}
                                                            sx={{
                                                              opacity: open ? 1 : 0,
                                                            }}
                                                          />
                                                          <ChevronRight />
                                                        </MenuListButton>
                                                      </Link>
                                                      {/* )} */}
                                                    </ListItem>
                                                  )
                                                }
                                              })}
                                        </MultiLevelList>
                                      </>
                                    </Collapse>
                                  )}
                              </>
                            ))}
                        </MultiLevelList>
                      </>
                    </Collapse>
                  )}
                </>
              );
            })}
          </List>
        </Swipeable>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          height: "100% !important",
          overflowX: "hidden",
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>

      {openResetDialog && (
        <ResetDialog viewDetails={viewDetails} sendReset={sendReset} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnack}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
