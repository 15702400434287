import React, { useEffect, useState, useRef } from "react";
import {
    ArrowForward,
    Close,
    Download,
    FilterList,
} from "@mui/icons-material";

import { httpclient } from "../../../../utils/httpClient";
import {
    Box,
    Button,
    Card,
    Collapse,
    Grid,
    InputLabel,
    styled,
    TextField,
    Snackbar,
    Select,
    MenuItem,
    FormControl,
    Autocomplete,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router";
import { CSVLink } from "react-csv";
import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";
import TWTableComponent from "../../../Online/TempleAndWebster/Temple&WebsterTable/TWTableComponent";
import ViewDetail from "./ViewDetail";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const columns = [
    { id: "customerID", name: "" },
    { id: "name", name: "Name" },
    { id: "statusInfo", name: "Status" },
    { id: "lastUpdated", name: "Last Updated" },
    { id: "lastError", name: "Last Error" },
    { id: "action", name: "Action" },
];

const FilteredBox = styled(Box)(({ theme }) => ({
    background: "#f9f9f9",
    padding: "5px 10px",
    borderRadius: "5px",
    "& p": {
        margin: "0",
        marginRight: "10px",
        display: "inline-block",
        background: "#dedede",
        borderRadius: "10px",
        padding: "2px 5px",
    },
    "& svg": {
        fontSize: "15px",
        cursor: "pointer",
        position: "relative",
        top: "3px",
        background: theme.palette.primary.dark,
        color: "#fff",
        borderRadius: "50%",
        padding: "2px",
        marginLeft: "2px",
    },
}));

const Header = styled("div")(({ theme }) => ({
    "& h1": {
        color: theme.palette.primary.dark,
        margin: "0",
    },
}));



const WorkflowMarketplace = () => {
    const location = useLocation();
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [ID, setID] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [viewDetails, setViewDetails] = useState({});
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [singleLoading, setSingleLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState("");




    useEffect(() => {
        getOrdersException()
    }, []);


    const getOrdersException = () => {
        setLoading(true);
        httpclient
            .get(`workflow/workFlowList`)
            .then(({ data }) => {
                if (data) {
                    console.log("dd", data)
                    setRows(data.data);
                    setLoading(false);
                }
            }).catch(err => {
                if (err.response.status === 401) {
                    localStorage.clear();
                    window.location = "/login";
                }
            })
    };

    const handleView = (row) => {
        setSingleLoading(true);
        setOpenViewDialog(true);
        setID(row.customerID)
        setCustomerName(row.name)
        // httpclient
        //     .get(`workflow/${row.customerID}/workFlowDetails`)
        //     .then(({ data }) => {
        //         if (data) {
        //             setViewDetails(data.data);
        //             setSingleLoading(false);
        //         }
        //     })
    };

    const sendDetails = (callback) => {
        if (callback.open === false) {
            setOpenViewDialog(false);
            setViewDetails({});
        }
    };

    const handleRefetch = () => {
        getOrdersException();
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };



    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <Header>
                        <h1>Workflow Marketplace</h1>
                    </Header>
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >

                    {location.state?.count && (
                        <div style={{ lineHeight: "1.0", fontFamily: "Poppins" }}><small>New Alert Issues : <strong>{location.state.count}</strong></small></div>)}

                </Grid>
                {/* Filter */}



                {/* Filter */}

                <Grid item xs={12}>
                    <TWTableComponent
                        columns={columns}
                        rows={rows}
                        sort={false}
                        view={true}
                        handleView={handleView}
                        loading={loading}
                        footer={false}

                    />
                </Grid>
            </Grid>

            {openViewDialog && (
                <ViewDetail
                    loading={singleLoading}
                    setLoading={setSingleLoading}
                    ID={ID}
                    customerName={customerName}
                    // viewDetails={viewDetails}
                    sendDetails={sendDetails}
                />
            )}

            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={messageState}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default WorkflowMarketplace;
